<template>
  <div id='quillEditorQiniu'>
    <!-- 基于elementUi的上传组件 el-upload begin-->
    <el-upload
        class="avatar-uploader"
        :action="uploadImgUrl"
        :accept="'image/*'"
        :show-file-list="false"
        :on-success="uploadEditorSuccess"
        :on-error="uploadEditorError"
        :before-upload="beforeEditorUpload"
        :headers="headers">
    </el-upload>
    <!-- 基于elementUi的上传组件 el-upload end-->
<!--    <div class="ql-container">-->
      <quill-editor class="ql-editor" v-model="content"
                    :ref="toref"
                    @change="onEditorChange($event)"
                    @focus="onEditorFocus($event)"
                    :options="editorOption" @ready="onEditorReady($event)" >
      </quill-editor>
<!--    </div>-->
  </div>
</template>

<script>
import Vue from "vue";

// //引入quill-editor编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

//实现quill-editor编辑器拖拽上传图片
import * as Quill from 'quill'
import { ImageDrop } from 'quill-image-drop-module'
Quill.register('modules/imageDrop', ImageDrop)

//实现quill-editor编辑器调整图片尺寸
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', ImageResize)

import Video from "./video";
Quill.register(Video, true);

import { htmlEditButton } from 'quill-html-edit-button';
Quill.register("modules/htmlEditButton", htmlEditButton);

// import {getToken} from "@/utils/auth";

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{'header': 1}, {'header': 2}],               // custom button values
  [{'list': 'ordered'}, {'list': 'bullet'}],
  [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
  [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
  [{'direction': 'rtl'}],                         // text direction

  [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
  [{'header': [1, 2, 3, 4, 5, 6, false]}],

  [{'color': []}, {'background': []}],          // dropdown with defaults from theme
  [{'font': []}],
  [{'align': []}],
  ['link', 'image', 'video'],
  ['clean'],                                         // remove formatting button
  ['htmlEditButton']
];

const titleConfig = [
  { Choice: '.ql-insertMetric', title: '跳转配置' },
  { Choice: '.ql-bold', title: '加粗' },
  { Choice: '.ql-italic', title: '斜体' },
  { Choice: '.ql-underline', title: '下划线' },
  { Choice: '.ql-header', title: '段落格式' },
  { Choice: '.ql-strike', title: '删除线' },
  { Choice: '.ql-blockquote', title: '块引用' },
  { Choice: '.ql-code', title: '插入代码' },
  { Choice: '.ql-code-block', title: '插入代码段' },
  { Choice: '.ql-font', title: '字体' },
  { Choice: '.ql-size', title: '字体大小' },
  { Choice: '.ql-list[value="ordered"]', title: '编号列表' },
  { Choice: '.ql-list[value="bullet"]', title: '项目列表' },
  { Choice: '.ql-direction', title: '文本方向' },
  { Choice: '.ql-header[value="1"]', title: 'h1' },
  { Choice: '.ql-header[value="2"]', title: 'h2' },
  { Choice: '.ql-align', title: '对齐方式' },
  { Choice: '.ql-color', title: '字体颜色' },
  { Choice: '.ql-background', title: '背景颜色' },
  { Choice: '.ql-image', title: '图像' },
  { Choice: '.ql-video', title: '视频' },
  { Choice: '.ql-link', title: '添加链接' },
  { Choice: '.ql-formula', title: '插入公式' },
  { Choice: '.ql-clean', title: '清除字体格式' },
  { Choice: '.ql-emoji', title: '表情包' },
  { Choice: '.ql-script[value="sub"]', title: '下标' },
  { Choice: '.ql-script[value="super"]', title: '上标' },
  { Choice: '.ql-indent[value="-1"]', title: '向左缩进' },
  { Choice: '.ql-indent[value="+1"]', title: '向右缩进' },
  { Choice: '.ql-header .ql-picker-label', title: '标题大小' },
  { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: '标题一' },
  { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: '标题二' },
  { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: '标题三' },
  { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: '标题四' },
  { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: '标题五' },
  { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: '标题六' },
  { Choice: '.ql-header .ql-picker-item:last-child', title: '标准' },
  { Choice: '.ql-size .ql-picker-item[data-value="small"]', title: '小号' },
  { Choice: '.ql-size .ql-picker-item[data-value="large"]', title: '大号' },
  { Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: '超大号' },
  { Choice: '.ql-size .ql-picker-item:nth-child(2)', title: '标准' },
  { Choice: '.ql-align .ql-picker-item:first-child', title: '居左对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="center"]', title: '居中对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="right"]', title: '居右对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="justify"]', title: '两端对齐' }
]

export default {
  name: "CustomEditor",
  props:{
    /*编辑器内容*/
    value:{
      type:String
    },
    editable: {
      type: Boolean,
      default: false
    },
    toref: {
      type: String,
      default:"quillEditor"
    },
  },
  data(){
    return{
      content:this.value,
      uploadImgUrl:"/m_api/admin/resources/upload",// 上传的图片服务器地址,
      headers: {
        // Authorization: "Bearer " + getToken(),
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      quillUpdateImg:false,
      editorOption:{
        placeholder:'请编辑文档内容',
        modules: {
          imageResize: {
            displayStyles:{
              backgroundColor:'black',
              border:'none',
              color:'white'
            },
            modules:['Resize','DisplaySize'],
          },
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': function (value) {
                if (value) {
                  document.querySelector('#quillEditorQiniu .avatar-uploader input').click()
                } else {
                  this.quill.format('image', false);
                }
              },
              // 'video': function (value) {
              //   if (value) {
              //     document.querySelector('#quillEditorQiniu .avatar-uploader input').click()
              //   } else {
              //     this.quill.format('video', false);
              //   }
              // }
            }
          },
          clipboard: {
            matchVisual: false,
          },
          htmlEditButton: {
            debug: false, // logging, default:false
            msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
            okText: "Ok", // Text to display in the OK button, default: Ok,
            cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
            buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
            // buttonHTML: "Html",
            buttonTitle: "显示HTML源文件", // Text to display as the tooltip for the toolbar button, default: Show HTML source
            syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
            prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
            editorModules: {} // The default mod
          }
        }
      },
    }
  },
  methods:{
    onEditorReady(quill){
      // console.log(quill);
      for (let item of titleConfig) {
        let tip = document.querySelector('.quill-editor ' + item.Choice)
        if (!tip) continue
        tip.setAttribute('title', item.title)
      }
    },
    onEditorFocus(event){
      // console.log('@editable@', this.editable);
      if (this.editable) {
        event.enable(true);
      } else {
        event.enable(false);
      }
    },
    onEditorChange(event){
      // console.log(this.content);
      // console.log('@editable@', this.editable);
      if(this.editable) {
        this.$emit('input',this.content);//将修改后的内容传到父组件
      } else {
        event.quill.enable(false);
      }
    },
    //上传图片之前async
    beforeEditorUpload(res, file){
      //显示上传动画
      // console.log(res);
      // console.log(file);
      this.quillUpdateImg = true;
      //  const res1 = await uploadImage()
      // console.log(res1,'=====');
      // this.$emit('before',res, file)
    },

    // 上传图片成功
    uploadEditorSuccess(res, file) {
      console.log(res, file);
      let imgUrl=res.path;
      console.log(imgUrl)
      let type=imgUrl.substring(imgUrl.lastIndexOf(".")+1);
      // 获取富文本组件实例
      let toeval = "this.$refs."+this.toref+".quill"
      // let quill = this.$refs.customQuillEditor.quill;
      let quill =  eval(toeval);
      console.log(quill);
      // 获取光标所在位置
      let length = quill.getSelection().index;
      if(type==='mp4'){
        window.jsValue=imgUrl;
        // 插入视频  res.info为服务器返回的图片地址
        quill.insertEmbed(length, 'video', imgUrl);
      }else {
        // 插入图片  res.info为服务器返回的图片地址
        quill.insertEmbed(length, 'image', imgUrl);
      }

      // 调整光标到最后
      quill.setSelection(length + 1);

      //取消上传动画
      this.quillUpdateImg = false;

    },

    // 上传图片失败
    uploadEditorError(res, file) {
      console.log(res,'====44444');
      console.log(file,'====44444');
      //页面提示
      this.$message.error('上传失败')

      //取消上传动画
      this.quillUpdateImg = false;
    },
  },
  created () {
    /*if(this.oldContent){
      this.content = this.oldContent
    }*/
  },
  mounted () {
   // console.log("开始")
  },
  watch:{
    value(newVal) {
      // console.log(newVal);
        this.content=newVal;
    }
  },
}
</script>

<style scoped>
.ql-container{
  position: relative;
  max-height: 40rem;
  height:40rem!important;
}

.ql-editor {
  line-height: normal !important;
  padding: 2px;
  margin-bottom: 10px;
}

/*.ql-editor ::-webkit-scrollbar{*/
/*  width: 10px;*/
/*  height: 10px;*/
/*}*/
/*.ql-editor ::-webkit-scrollbar-thumb{*/
/*  background: #666666;*/
/*  border-radius: 5px;*/
/*}*/
/*.ql-editor ::-webkit-scrollbar-track{*/
/*  background: #ccc;*/
/*  border-radius: 5px;*/
/*}*/

.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
