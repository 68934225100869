<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>招商板块</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="article-search">
      <el-form-item>
        <el-input
            suffix-icon="el-icon-search"
            size="small" v-model="formInline.keyword"
            @keyup.enter.native="search"
            placeholder="请输入关键字"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-plus" class="cus-add-button" @click="handleEdit()">添加新的港澳专区信息</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table
        size="small" highlight-current-row   element-loading-text="加载中……" style="width: 100%;"
        :row-class-name = "tableRowClassName"
        v-loading="loading"
        :data="listData"
    >

      <el-table-column align="center" type="selection" width="60"></el-table-column>
      <el-table-column prop="status" label="发布状态" width="80">
        <template slot-scope="scope">
            <span  v-if="scope.row.status == 1" class="publish-active">已发布</span>
            <span  v-if="scope.row.status != 1" class="publish-inactive">已隐藏</span>
        </template>
      </el-table-column>

      <el-table-column prop="poster" label="封面图片" width="240">
        <template slot-scope="scope">
          <el-image v-if="scope.row.banner" style="width: 260px; height: 100px" :src="scope.row.banner" fit="fill"></el-image>
          <el-empty v-else :image-size="100" style="height: 100px" ></el-empty>
        </template>
      </el-table-column>


      <el-table-column sortable prop="name" label="左侧展示文字SC/TC/EN" width="400">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.cn_label }}</div>
            <div>{{ scope.row.tc_label }}</div>
            <div>{{ scope.row.en_label }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column sortable prop="name" label="右侧文字SC/TC/EN" width="500">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.cn_desc }}</div>
            <div>{{ scope.row.tc_desc }}</div>
            <div>{{ scope.row.en_desc }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" min-width="120">
        <template slot-scope="scope">
          <div><el-button size="mini" type="primary" class="cus-edit-button" @click="handleEdit(scope.$index, scope.row)">编辑</el-button></div>
          <div style="margin-top: 10px"><el-button size="mini" type="danger" class="cus-deleted-button"  @click="onDelete(scope.$index, scope.row)">删除</el-button></div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 编辑界面 -->
    <el-drawer :title="title" :visible.sync="drawer" size="70%">
      <PromotionListItem :reload="search" :close="changeDrawer" :language="formInline.language" :pId="selectedTagId"></PromotionListItem>
    </el-drawer>
  </div>
</template>

<script>
import { listPromotions, removePromotion } from "@/api";
import Pagination from '@/components/Pagination'
import PromotionListItem from "@/views/promotion/PromotionListItem";

export default {
  name: 'PromotionList',
  data() {
    return {
      nshow: true, //switch开启
      fshow: false, //switch关闭
      loading: false, //是显示加载
      drawer: false,
      selectedTagId: null,
      title: '',
      formInline: {
        page: 0,
        size: 10,
        keyword: '',
        group: '',
        language: 'EN'
      },
      tagGroups: [],
      userparm: [], //搜索权限
      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 0,
        pageSize: 10,
        total: 10
      }
    }
  },
  // 注册组件
  components: {
    PromotionListItem,
    Pagination
  },
  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.getdata(this.formInline);
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    getdata(parameter) {
      this.loading = true

      listPromotions(parameter).then(res => {
        this.loading = false;
        if (res.numberOfElements > 0) {
          console.log('Get services: ', res.totalElements, res.totalPages, res.number, res.size);
          this.listData = res.data;
          this.pageparm.currentPage = res.number + 1;
          this.pageparm.pageSize = res.size;
          this.pageparm.total = res.totalElements;
        } else {
          console.log('@ no data.')
        }
      }).catch(error => {
        this.loading = false;
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      })

    },
    tableRowClassName({row, rowIndex}) {
      if (row.status === 0) {
        return 'warning-row';
      }
      if (row.priority === 9) {
        return 'top-row';
      }
      return '';
    },

    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage - 1
      this.formInline.size = parm.pageSize
      this.getdata(this.formInline)
    },
    // 搜索事件
    search() {
      this.getdata(this.formInline)
    },
    //显示编辑界面
    handleEdit: function(index, row) {
      if (row !== undefined && row !== 'undefined') {
        this.title = '修改招商信息'
        this.selectedTagId = row.id;
      } else {
        this.title = '添加招商信息'
        this.selectedTagId = null;
      }
      this.drawer = true;
    },
    changeDrawer() {
      this.drawer = !this.drawer;
    },

    onDelete(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('call remove benefit api: ', index, row);
        const params = {id: row.id};
        removePromotion(params).then(res => {
          setTimeout(() => {
            this.$message({
              type: 'success',
              message: '该标签已删除!'
            });
            this.getdata(this.formInline);
          }, 1000);
        }).catch(err => {
          this.loading = false
          console.log('Error: ', err);
          this.$message.error('该信息删除失败，请稍后再试！')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

  }
}
</script>

<style scoped>
.article-search {
  margin-top: 20px;
}

.el-table .warning-row {
  background-color: oldlace;
}

.el-table .top-row {
  background-color: #f0f9EB;
}

.publish-active {
  border-radius: 6px;
  border: 0.5px solid #E5F2DB;
  background: #F2F9EC;
  color: #92C96A;
  padding: 2px 10px;
}

.publish-inactive {
  border-radius: 6px;
  border: 0.5px solid rgba(245, 148, 4, 0.50);
  background: rgba(245, 148, 4, 0.20);
  color: #F59404;
  padding: 2px 10px;
}

</style>
