<template xmlns:el-col="http://www.w3.org/1999/html">
  <div class="b-form"  style="background: #EFEFEF;padding: 20px">
    <el-form ref="benefitForm" :model="benefit"  size="small" label-position="top">

      <el-row gutter="20">
        <el-col :span="16">
          <div class="cus-card">
            <el-form-item label="简体中文标题" prop="title">
              <el-input v-model="benefit.title.CN" aria-placeholder="e.g. 深圳市境外高端人才和紧缺人才个人所得税财政补贴"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文标题" prop="title">
              <el-input v-model="benefit.title.TC" aria-placeholder="e.g.  深圳市境外高端人才和緊缺人才個人所得稅財政補貼"></el-input>
            </el-form-item>

            <el-form-item label="English标题" prop="title">
              <el-input v-model="benefit.title.EN" aria-placeholder="e.g.  Individual Income Tax Subsidies for Overseas High-end Talents and Talents in Short Supply in Shenzhen City"></el-input>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文摘要" prop="summary">
              <el-input v-model="benefit.summary.CN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文摘要" prop="summary">
              <el-input v-model="benefit.summary.TC" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>

            <el-form-item label="English摘要" prop="summary">
              <el-input v-model="benefit.summary.EN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文正文内容" prop="content">
              <CustomEditor ref="quillEditor_CN" :value="htmlContent.CN" toref="quill_CN" :editable="editable"/>
            </el-form-item>

            <el-form-item label="繁体中文正文内容" prop="content">
              <CustomEditor ref="quillEditor_TC" :value="htmlContent.TC" toref="quill_TC" :editable="editable"/>
            </el-form-item>

            <el-form-item label="English正文内容" prop="content">
              <CustomEditor ref="quillEditor_EN" :value="htmlContent.EN" toref="quill_EN" :editable="editable"/>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文搜索引擎元描述" prop="meta_desc">
              <el-input v-model="benefit.meta_desc.CN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文搜索引擎元描述" prop="meta_desc">
              <el-input v-model="benefit.meta_desc.TC" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>

            <el-form-item label="English搜索引擎元描述" prop="meta_desc">
              <el-input v-model="benefit.meta_desc.EN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
          </div>

        </el-col>

        <el-col :span="8">
          <div style="margin-top: -40px;position: fixed;top: 60px;right: 80px;z-index: 99999">
            <el-form-item >
<!--              <el-button @click="onRefresh">刷新</el-button>-->
              <el-button class="cus-save-button" @click="onSubmit('benefitForm')">保存</el-button>
<!--              <el-button type="info" @click="onClose()">取消</el-button>-->
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="发布状态" prop="status">
              <el-switch v-model="isRelease" @change="setRelease" active-text="前端可见" active-color="#000000"	 inactive-text="已隐藏"/>
            </el-form-item>
            <el-form-item label="排序" prop="status">
              <el-switch v-model="isTop" @change="setTop" active-color="#000000" active-text="置顶" inactive-text="常规"/>
            </el-form-item>
            <el-form-item label="港澳" prop="status">
              <el-switch v-model="benefit.bay" active-color="#000000" active-text="面向港澳" />
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="业务范畴" prop="catalog">
              <el-select v-model="benefit.catalog" allow-create filterable placeholder="请选择业务范畴">
                <el-option v-for="item in benefitCatalogs" :key="item.code" :value="item.code" :label="item.cnlabel"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="人才类型" prop="categories">
              <el-select v-model="benefit.categories" multiple placeholder="请选择人才类型">
                <el-option value="FT" label="外籍人才"></el-option>
                <el-option value="HKMT" label="港澳人才"></el-option>
                <el-option value="MT" label="大陆人才"></el-option>
                <el-option value="RT" label="海外回国人员"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="人才场景" prop="scenes">
              <el-select v-model="benefit.scenes" multiple placeholder="请选择人才场景">
                <el-option value="Work" label="工作"></el-option>
                <el-option value="Study" label="学习"></el-option>
                <el-option value="Invest" label="创业"></el-option>
                <el-option value="Life" label="置业"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="封面图片" prop="poster">
              <div style="color: rgba(54, 65, 85, 0.50);font-size: 14px;margin-top: -10px;margin-bottom: 5px">图片建议尺寸为100px * 100px</div>
              <el-upload
                  list-type="picture-card"
                  :action="uploader.url"
                  :accept="'image/*'"
                  :limit="1"
                  :on-preview="handlePosterPreview"
                  :on-remove="handlePosterRemove"
                  :on-success="uploadPosterSuccess"
                  :on-error="uploadPosterError"
                  :file-list="uploader.posterList"
                  :headers="uploader.headers">
                <i class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <el-dialog :visible.sync="uploader.posterVisible">
                <img :src="benefit.poster" alt="">
              </el-dialog>
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="横幅图片" prop="banner">
              <div style="color: rgba(54, 65, 85, 0.50);font-size: 14px;margin-top: -10px;margin-bottom: 5px">图片建议尺寸为1255px * 400px</div>
              <el-upload
                  list-type="picture-card"
                  :action="uploader.url"
                  :accept="'image/*'"
                  :limit="1"
                  :on-preview="handleBannerPreview"
                  :on-remove="handleBannerRemove"
                  :on-success="uploadBannerSuccess"
                  :on-error="uploadBannerError"
                  :file-list="uploader.bannerList"
                  :headers="uploader.headers">
                <i class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <el-dialog :visible.sync="uploader.bannerVisible">
                <img :src="benefit.banner" alt="">
              </el-dialog>
            </el-form-item>
          </div>
        </el-col>

      </el-row>






      <!--      <div style="background: #ffffff;padding: 20px;margin-top: 100px">-->
      <!--        <el-row>-->
      <!--          <el-col :span="8">-->
      <!--            <el-form-item label="语言" prop="language">-->
      <!--              <el-select v-model="benefit.language" :disabled="lockedLanguage" placeholder="请选择文档语言">-->
      <!--                <el-option value="EN" label="英语"></el-option>-->
      <!--                <el-option value="CN" label="简体中文"></el-option>-->
      <!--                <el-option value="TC" label="繁体中文"></el-option>-->
      <!--              </el-select>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--          <el-col :span="8">-->
      <!--            <el-form-item label="人才类型" prop="categories">-->
      <!--              <el-select v-model="benefit.categories" multiple placeholder="请选择人才类型">-->
      <!--                <el-option value="FT" label="外籍人才"></el-option>-->
      <!--                <el-option value="HKMT" label="港澳人才"></el-option>-->
      <!--                <el-option value="MT" label="大陆人才"></el-option>-->
      <!--                <el-option value="RT" label="海外回国人员"></el-option>-->
      <!--              </el-select>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--          <el-col :span="8">-->
      <!--            <el-form-item label="人才场景" prop="scenes">-->
      <!--              <el-select v-model="benefit.scenes" multiple placeholder="请选择人才场景">-->
      <!--                <el-option value="Work" label="工作"></el-option>-->
      <!--                <el-option value="Study" label="学习"></el-option>-->
      <!--                <el-option value="Invest" label="创业"></el-option>-->
      <!--                <el-option value="Life" label="置业"></el-option>-->
      <!--              </el-select>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--        </el-row>-->


      <!--        <el-row>-->
      <!--          <el-col :span="8">-->
      <!--            <el-form-item label="业务范畴" prop="catalog">-->
      <!--              <el-select v-model="benefit.catalog" allow-create filterable placeholder="请选择业务范畴">-->
      <!--                <el-option v-for="item in benefitCatalogs" :key="item.code" :value="item.code" :label="item.cnlabel"></el-option>-->
      <!--              </el-select>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--          <el-col :span="8">-->
      <!--            <el-form-item label="文档状态" prop="status">-->
      <!--              <el-switch v-model="isRelease" @change="setRelease" active-text="已发布" inactive-text="未发布"/>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--          <el-col :span="8">-->

      <!--            <el-form-item>-->
      <!--              <el-switch v-model="isTop" @change="setTop" active-text="置顶" />-->
      <!--              <el-divider direction="vertical"/>-->
      <!--              <el-switch v-model="benefit.bay" active-text="面向港澳" />-->
      <!--            </el-form-item>-->

      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--        <el-row>-->
      <!--          <el-col :span="8">-->
      <!--            <el-form-item label="封面图片" prop="poster">-->
      <!--              <el-upload-->
      <!--                  list-type="picture-card"-->
      <!--                  :action="uploader.url"-->
      <!--                  :accept="'image/*'"-->
      <!--                  :limit="1"-->
      <!--                  :on-preview="handlePosterPreview"-->
      <!--                  :on-remove="handlePosterRemove"-->
      <!--                  :on-success="uploadPosterSuccess"-->
      <!--                  :on-error="uploadPosterError"-->
      <!--                  :file-list="uploader.posterList"-->
      <!--                  :headers="uploader.headers">-->
      <!--                <i class="el-icon-plus avatar-uploader-icon" />-->
      <!--              </el-upload>-->
      <!--              <el-dialog :visible.sync="uploader.posterVisible">-->
      <!--                <img :src="benefit.poster" alt="">-->
      <!--              </el-dialog>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--          <el-col :span="8">-->
      <!--            <el-form-item label="横幅图片" prop="banner">-->
      <!--              <el-upload-->
      <!--                  list-type="picture-card"-->
      <!--                  :action="uploader.url"-->
      <!--                  :accept="'image/*'"-->
      <!--                  :limit="1"-->
      <!--                  :on-preview="handleBannerPreview"-->
      <!--                  :on-remove="handleBannerRemove"-->
      <!--                  :on-success="uploadBannerSuccess"-->
      <!--                  :on-error="uploadBannerError"-->
      <!--                  :file-list="uploader.bannerList"-->
      <!--                  :headers="uploader.headers">-->
      <!--                <i class="el-icon-plus avatar-uploader-icon" />-->
      <!--              </el-upload>-->
      <!--              <el-dialog :visible.sync="uploader.bannerVisible">-->
      <!--                <img :src="benefit.banner" alt="">-->
      <!--              </el-dialog>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--          <el-col :span="8">-->
      <!--            <el-form-item>-->
      <!--              <el-switch v-model="editable" active-text="是否编辑内容" />-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--        <el-row>-->
      <!--          <el-col :span="24">-->
      <!--            <el-form-item label="标题" prop="title">-->
      <!--              <el-input v-model="benefit.title" aria-placeholder="请输入标题"></el-input>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--        <el-row>-->
      <!--          <el-col :span="18">-->
      <!--            <el-form-item label="通用标签" prop="tags">-->
      <!--              <template>-->
      <!--                <el-tag v-for="(item, idx) in benefit.tags" :key="idx" closable-->
      <!--                        :type="idx % 2 === 0 ? 'warning' : ''" effect="plain"-->
      <!--                        @close="removeTag(item)"-->
      <!--                > {{ item }}</el-tag>-->
      <!--              </template>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--          <el-col :span="6">-->
      <!--            <el-form-item>-->
      <!--              <el-button type="success" @click="openDialog">添加通用标签</el-button>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--        <el-row>-->
      <!--          <el-col :span="24">-->
      <!--            <el-form-item label="摘要" prop="summary">-->
      <!--              <el-input v-model="benefit.summary" type="textarea" :rows="2" aria-placeholder="请输入摘要"></el-input>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--        <el-row>-->
      <!--          <el-col :span="24">-->
      <!--            <el-form-item label="内容" prop="content">-->
      <!--              <CustomEditor ref="quillEditor" :value="htmlContent" :editable="editable"/>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--        <el-row>-->
      <!--          <el-col :span="24">-->
      <!--            <el-form-item >-->
      <!--              <el-button @click="onRefresh">刷新</el-button>-->
      <!--              <el-button type="primary" @click="onSubmit('benefitForm')">保存</el-button>-->
      <!--              <el-button type="info" @click="onClose()">取消</el-button>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--      </div>-->

    </el-form>
    <!--  维护通用标签  -->
    <el-dialog title="添加通用标签" :visible.sync="tagDialogVisible" :append-to-body="true" :modal-append-to-body='false' width="30%" @click='closeDialog'>
      <el-input v-model="tag" ref="tagInput" placeholder="请输入标签关键字" @keyup.enter.native="addTag"></el-input>
      <em class="tips"><i class="el-icon-info"></i>输入回车添加标签</em>
    </el-dialog>
  </div>
</template>

<script>
import CustomEditor from "@/components/CustomEditor";
import {getBenefit, addBenefit, editBenefit, getBenefit2, editBenefit2, addContent2} from "@/api";
import { benefitCatalogs } from "@/utils/constants";

export default {
  name: 'BenefitListItem',
  components: {CustomEditor},
  props: {
    articleId: {
      type: String
    },
    language: {
      type: String
    },
    // data: {
    //   type: Object
    // },
    close: {
      type: Function
    },
    reload: {
      type: Function
    }
  },
  data() {
    return {
      benefit: {
        id: '',
        language: '',
        title: {CN: '', TC: '', EN: ''},
        poster: '',
        banner: '',
        meta_desc: {CN: '', TC: '', EN: ''},
        summary: {CN: '', TC: '', EN: ''},
        content: '',
        catalog: '',
        scenes: [],
        categories: [],
        department: '',
        tags: [],
        priority: 1,
        bay: false,
        status: 0,
      },
      // benefit: this.data,
      htmlContent: {CN: '', TC: '', EN: ''},
      rules: {
        // language: [{required: true, message: '请选择语言', trigger: 'blur'}],
        catalog: [{required: true, message: '请选择业务范畴', trigger: 'blur'}],
        scenes: [{required: true, message: '请选择人才场景', trigger: 'blur'}],
        categories: [{required: true, message: '请选择人才类型', trigger: 'blur'}],
        // title: [
        //   {required: true, message: '请输入文档标题', trigger: 'blur'},
        //   {min: 1, max: 256, message: '标题长度在5 到 256个字符', trigger: 'blur'},
        // ],
        // summary: [{max: 256, message: '标题长度小于1000个字符', trigger: 'blur'},
        // ],
      },
      uploader: {
        url:"/m_api/admin/resources/upload", // 图片上传请求地址,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        posterList: [],
        bannerList: [],
        posterVisible: false,
        bannerVisible: false,
      },
      lockedLanguage: false,
      isTop: false,
      isRelease: false,
      editable: true,
      contentHeight: 520,
      benefitCatalogs: benefitCatalogs,
      tag: '',
      tagDialogVisible: false,
    }
  },
  methods: {
    setTop() {
      this.benefit.priority = this.isTop ?  9 : 1;
    },
    setRelease() {
      this.benefit.status = this.isRelease ? 1 : 0;
    },
    onRefresh() {
      if (this.articleId) {
        this.getBenefitData(this.articleId, this.language);
      }
    },
    onSubmit(formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        console.log("valid --------- ");
        console.log(valid)
        if (valid) {
          console.log(this.articleId)
          if (this.articleId) {
            console.log('#, ', this.benefit.content);

            if (this.editable) {
              console.log('##, ', this.htmlContent);
              // this.benefit.content = this.htmlContent.replace(/<p><br><\/p>/g,"").replace(/<div><br><\/div>/g,"");
              this.benefit.content = this.htmlContent;
              console.log('###, ', this.benefit.content);
            }
            console.log(this.benefit);
            const params = { articleId: this.articleId };
            editBenefit2(params, this.benefit).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.id);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          } else {
            console.log(this.htmlContent);
            this.benefit.content = this.htmlContent;

            this.benefit.topic = "Benefit";
            this.benefit.topic_title = "B";
            addContent2(this.benefit).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.id);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          }
          setTimeout(()=>{
            this.reload();
            this.close();
            // this.editable = false;
            // this.initBenefit();
          }, 1000);
        } else {
          console.log(this.benefit);
          return false;
        }
      });
    },
    onClose() {
      // this.editable = false;
      this.close();
    },
    getBenefitData(articleId, language) {
      this.initBenefit();
      // console.log('@ ', articleId, language);
      const params = {
        articleId,
        language
      }
      getBenefit2(params).then(res => {
        console.log('Get benefits: ', this.benefit);

        if (res.articleId) {
          this.benefit = res;
          this.benefit.id = res.articleId;
          this.htmlContent = res.content;
          console.log(this.htmlContent)
          this.isTop = this.benefit.priority === 9;
          this.isRelease = this.benefit.status > 0;
          if (this.benefit && this.benefit.tags == null) this.benefit.tags = [];

          this.uploader.posterList = [];
          if (this.benefit.poster) {
            this.uploader.posterList.push({name: 'poster', url: this.benefit.poster});
          }
          this.uploader.bannerList = [];
          console.log('@', this.benefit.banner);
          if (this.benefit.banner) {
            this.uploader.bannerList.push({name: 'banner', url: this.benefit.banner});
          }
          this.lockedLanguage = true;
        } else {
          this.$message.error('该文档数据不存在，编号：' + articleId);
        }
      }).catch(error => {
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      });
    },
    changeContentCN(value) {
      console.log('@ call changeContent' );
      this.htmlContent.CN = value;
    },
    changeContentTC(value) {
      console.log('@ call changeContent' );
      this.htmlContent.TC = value;
    },
    changeContentEN(value) {
      console.log('@ call changeContent' );
      this.htmlContent.EN = value;
    },
    initBenefit() {
      this.benefit.id = '';
      this.benefit.language = this.language;
      this.benefit.title =  {CN: '', TC: '', EN: ''};
      this.benefit.poster = '';
      this.benefit.banner = '';
      this.benefit.summary =  {CN: '', TC: '', EN: ''};
      this.benefit.content =  {CN: '', TC: '', EN: ''};
      this.benefit.meta_desc =  {CN: '', TC: '', EN: ''};
      this.benefit.catalog = '';
      this.benefit.scenes = [];
      this.benefit.categories = [];
      this.benefit.department = '';
      this.benefit.tags = [];
      this.benefit.priority = 1;
      this.benefit.bay = false;
      this.benefit.status = 0;
      this.uploader.posterList = [];
      this.uploader.bannerList = [];
      this.htmlContent =  {CN: '', TC: '', EN: ''};
      this.tag = '';
    },
    handlePosterPreview(file) {
      console.log('@poster@', file);
      this.uploader.posterVisible = true;
    },
    handlePosterRemove(file, fileList) {
      this.benefit.poster = '';
      console.log('@poster@', file, fileList);
    },
    uploadPosterSuccess(res, file) {
      console.log('@poster@: ', res);
      this.benefit.poster = res.path;
      console.log('@poster@: ', file);
    },
    uploadPosterError() {
      this.$message.error("封面图片上传失败");
    },
    handleBannerPreview(file) {
      console.log('@banner@', file);
      this.uploader.bannerVisible = true;
    },
    handleBannerRemove(file, fileList) {
      console.log('@banner@', file, fileList);
      this.benefit.banner='';
    },
    uploadBannerSuccess(res, file) {
      console.log('@banner@: ', res);
      this.benefit.banner = res.path;
      console.log('@banner@: ', file);
    },
    uploadBannerError() {
      this.$message.error("横幅图片上传失败");
    },
    openDialog() {
      this.tagDialogVisible = true;
      this.$nextTick(() => {this.$refs.tagInput.focus()})
    },
    closeDialog() {
      this.tagDialogVisible = false;
    },
    addTag() {
      console.log('tags: ', this.benefit.tags);
      if (!this.benefit.tags) this.benefit.tags = [];
      if (this.tag) {
        this.benefit.tags.push(this.tag);
        this.tag = '';
      }
    },
    removeTag(tag) {
      this.benefit.tags.splice(this.benefit.tags.indexOf(tag), 1);
    },
  },
  watch: {
    articleId(newValue, oldValue) {
      // console.log('@@', newValue, oldValue);
      if (newValue) {
        console.log('Edit benefit,', newValue, oldValue);
        const lang = this.benefit.language ? this.benefit.language : this.language;
        this.getBenefitData(newValue, lang);
      } else {
        this.lockedLanguage = false;
        this.initBenefit();
        console.log('New benefit');
      }
    },
    language(value) {
      if (value) {
        this.getBenefitData(this.articleId, value);
      }
    }
  },
  mounted() {
    // console.log('@', this.props.articleId);
    // console.log(this.benefit.id && this.benefit.id === '');
    // this.event.language = this.language;
    // if (this.articleId) {
    //   if (this.benefit.id === '') {
    //     this.getBenefitData(this.articleId, this.language);
    //   }
    // }

    this.onRefresh();

    this.$refs.quillEditor_CN.$on("input", this.changeContentCN);
    this.$refs.quillEditor_TC.$on("input", this.changeContentTC);
    this.$refs.quillEditor_EN.$on("input", this.changeContentEN);
  },
}
</script>

<style scoped>
.cus-card{
  border-radius: 15px;
  background: #FFF;
  padding: 20px;
  margin-bottom: 20px;
}
.avatar-uploader .el-update {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-update:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.tips {
  line-height: 16px;
}

.b-form .el-form-item__label{
  color: #364155 !important;;
  font-size: 14px !important;;
  font-style: normal !important;;
  font-weight: 600 !important;
  line-height: normal;
  padding: 0px !important;
  margin-top: 0px;
  background: red;
}
</style>
