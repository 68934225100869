<template>
  <div id="home">
    <!-- <el-form>
      <el-form-item prop="content" label="消息内容" :label-width="dialogFormLabelWidth">
        <Editor v-model="dialogForm.content"/>
      </el-form-item>
    </el-form> -->
    <router-view></router-view>
  </div>
</template>

<script>
  import Editor from './components/CustomEditor.vue'
  import 'element-ui/lib/theme-chalk/index.css';
  import {e} from 'element-ui';

  export default {
    name: 'App',
    components: {
      Editor
    },
    data() {
      return {
        dialogFormLabelWidth: '10%',
        dialogForm: {
          content: ''
        }
      }
    },
  }
</script>

<style>
html,
body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}
#home {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: ;
  color: #2c3e50;
  /*margin-top: 60px;*/
  height: 100%;
}
</style>
