import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
    state: {
        authorized: false
    },
    mutations: {
        // 登录
        login(state, value) {
            console.log('login',state.user, value);
            state.authorized = value;
            localStorage.setItem("userState", value);
        },
        // 退出
        logout(state, value) {
            state.authorized = value;
            localStorage.removeItem("userState");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userInfo");
        }
    }
})