<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/questionnaire/talentReport'}">问卷管理（人才报告）</el-breadcrumb-item>
      <el-breadcrumb-item>选项维护</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search-container">
      <el-button @click="openDialog">添加选项</el-button>
    </div>
    <el-table
        size="small" highlight-current-row  border element-loading-text="加载中……" style="width: 100%;"
        :data="listData"
    >
      <el-table-column label="序号" width="60">
        <template slot-scope="scope">
          {{scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="问题名称" width="300"></el-table-column>
      <el-table-column prop="bindingType" label="绑定类型" width="100"></el-table-column>
      <el-table-column prop="value" label="值" width="200"></el-table-column>
      <el-table-column align="center" fixed="right" label="操作" min-width="200">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteOption(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="title" :visible.sync="optionFormVisible" :append-to-body="true">
      <el-form :model="option"  ref="optionForm" size="small" label-width="80px">
        <el-form-item label="选项标题" prop="name">
          <el-input ref="optionNameInput" v-model="option.name"></el-input>
        </el-form-item>
        <el-form-item label="绑定类型" prop="bindingType">
          <el-select v-model="option.bindingType" placeholder="请选择绑定的文档类别">
            <el-option v-for="item in bindingTypeList" :key="item.code" :value="item.code" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="值" prop="value">
          <el-select v-show="option.bindingType === 'CATALOG'" v-model="optional.value1" placeholder="请选择政策的业务范围">
            <el-option v-for="item in benefitList" :key="item.code" :value="item.code" :label="item.cnlabel"></el-option>
          </el-select>
          <el-select v-show="option.bindingType === 'SCENE'" v-model="optional.value2" placeholder="请选择人才场景">
            <el-option v-for="item in talentSceneList" :key="item.code" :value="item.code" :label="item.cnlabel"></el-option>
          </el-select>
          <el-select v-show="option.bindingType === 'CATEGORY'" v-model="optional.value3" placeholder="请选择人才类型">
            <el-option v-for="item in talentCategoryList" :key="item.code" :value="item.code" :label="item.cnlabel"></el-option>
          </el-select>
          <el-cascader
              v-show="option.bindingType === 'TAG'"
              v-model="optional.value4"
              :options="tagOptions"
              :show-all-levels="false"
              :props="{multiple: true, emitPath: false, value: 'name'}"
              collapse-tags
              clearable>
          </el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="optionFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('optionForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addQuestionOption,
  delQuestionOption,
  editQuestionOption,
  getQuestionOptions,
  listTagOptions,
  removeQuestion
} from "@/api";
import {benefitCatalogs, bindingTypes, talentCategories, talentScenes} from "@/utils/constants";

export default {
  name: "OptionList",
  props: {
    questionId: {
      type: String,
    },
  },
  data() {
    return {
      childDrawer: false,
      title: '',
      option: {
        id: '',
        name: '',
        questionId: '',
        bindingType: '',
        value: ''
      },
      rules: {
        name: [{required: true, message: '请输入选项的内容', trigger: 'blur'}],
      },
      optional: {
        value1: '',
        value2: '',
        value3: '',
        value4: []
      },
      listData: [],
      tagOptions: [],
      optionFormVisible: false,
      bindingTypeList: bindingTypes,
      benefitList: benefitCatalogs,
      talentSceneList: talentScenes,
      talentCategoryList: talentCategories,
    };
  },
  mounted() {
    if (this.questionId) {
      this.getData(this.questionId);
    }
    this.getTagOptions();
  },
  watch: {
    questionId(value) {
      if (value) {
        this.getData(value);
      }
    },
  },
  methods: {
    close() {
      // this.childDrawer = false;
      this.$emit('closeTagDrawer', false);
    },
    open() {
      this.innerDrawer = true;
    },
    handleClose() {
      // this.$confirm('还有未保存的工作哦确定关闭吗？')
      //     .then(_ => {
      //       done();
      //       this.close();
      //     })
      //     .catch(_ => {});
      this.close();
    },
    getData(id) {
      if (!this.questionId) {
        this.$message.warning('文档编号为空');
      } else {
        const params = {questionId: id}
        getQuestionOptions(params).then(res => {
          if(res) {
            this.listData = res;
          } else {
            this.$message.warning('该文档未添加标签');
          }
        }).catch(error => {
          console.log('#Get article tags error:', error);
          this.$message.error('获取标签列表错误');
        })
      }
    },
    handleEdit: function(index, row) {
      console.log(index, row);
      if (row !== undefined || row !== 'undefined') {
        this.option.id = row.id;
        this.option.name = row.name;
        this.option.bindingType = row.bindingType;
        this.option.value = row.value;
        this.optionFormVisible = true;
        if (row.bindingType) {
          switch (row.bindingType) {
            case 'CATALOG':
              this.optional.value1 = this.option.value;
              break;
            case 'SCENE':
              this.optional.value2 = this.option.value;
              break;
            case 'CATEGORY':
              this.optional.value3 = this.option.value;
              break;
            case 'TAG':
              this.optional.value4 = this.option.value.split(",");
          }
        }
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (this.option.bindingType) {
            case 'CATALOG':
              this.option.value = this.optional.value1;
              break;
            case 'SCENE':
              this.option.value = this.optional.value2;
              break;
            case 'CATEGORY':
              this.option.value = this.optional.value3;
              break;
            case 'TAG':
              this.option.value = this.optional.value4.join(",");
          }
          this.option.questionId = this.questionId;
          if (this.option.id) {
            this.title = '修改问题的选项';
            const params = {optionId: this.option.id}
            editQuestionOption(params, this.option).then(res => {
              if (res) {
                this.$message.success('修改问题选项成功');
              }
            }).catch(err => {
              console.error('保存问题选项失败：', err);
              this.$message.error('保存问题选项失败');
            })
          } else {
            this.title = '添加问题的选项';
            console.log('add option: ', this.option);
            addQuestionOption(this.option).then(res => {
              this.listData.push(res);
              this.$message.success('保存问题选项成功，id: ' + res.id);
            }).catch(error => {
              console.error('修改问题选项失败: ', error);
              this.$message.error("修改问题选项失败");
            })
          }
          setTimeout(()=>{
            this.getData(this.questionId);
            this.optionFormVisible = false;
          }, 1000);
        } else {
          console.log('@Option@', this.option);
          return false;
        }
      });
    },
    deleteOption: function(index, row) {
      this.$confirm('确定要删除吗?', '问题选项', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {optionId: row.optionId};
        delQuestionOption(params).then(res => {
          setTimeout(() => {
            this.$message.success('该问题选项已删除!');
            this.getdata(this.questionId);
          }, 1000);
        }).catch(err => {
          this.loading = false
          console.log('Error: ', err);
          this.$message.error('该问题删除失败，请稍后再试！');
        })
      }).catch(() => {
        this.$message.warning('已取消删除');
      });
    },
    openDialog() {
      this.optionFormVisible = true;
      this.$nextTick(() => {this.$refs.optionNameInput.focus()})
    },
    getTagOptions() {
      listTagOptions({}).then(res => {
        this.tagOptions = res;
      }).catch(error => {
        console.log('#Get group tags error:', error);
        this.$message.error('标签级联数据获取失败');
      })
    },
  },
}
</script>

<style scoped>
.search-container {
  min-height: 45px;
  float: right;
  margin-right: 10px;
}
</style>