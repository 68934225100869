<template xmlns:el-col="http://www.w3.org/1999/html">
  <div class="b-form"  style="background: #EFEFEF;padding: 20px">
    <el-form ref="companyForm" :model="company" :rules="rules" size="small" label-width="80px" label-position="top">


      <el-row gutter="20">
        <el-col :span="16">
          <div class="cus-card">
            <el-form-item label="简体中文标题" prop="title">
              <el-input v-model="company.simplified_name" aria-placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="繁体中文标题" prop="title">
              <el-input v-model="company.traditional_name" aria-placeholder=""></el-input>
            </el-form-item>

            <el-form-item label="English标题" prop="title">
              <el-input v-model="company.name" aria-placeholder=""></el-input>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文摘要" prop="summary">
              <el-input v-model="company.simplified_desc" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文摘要" prop="summary">
              <el-input v-model="company.traditional_desc" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>

            <el-form-item label="English摘要" prop="summary">
              <el-input v-model="company.description" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
          </div>

        </el-col>

        <el-col :span="8">
          <div style="margin-top: -40px;position: fixed;top: 60px;right: 80px;z-index: 99999">
            <el-form-item >
              <!--              <el-button @click="onRefresh">刷新</el-button>-->
              <el-button class="cus-save-button" @click="onSubmit('companyForm')">保存</el-button>
              <!--              <el-button type="info" @click="onClose()">取消</el-button>-->
            </el-form-item>
          </div>


          <div class="cus-card">

            <el-form-item label="联系人" prop="summary">
              <el-input v-model="company.contact" aria-placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="summary">
              <el-input v-model="company.contact_way" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="企业英文标签" prop="summary">
              <el-input v-model="company.tag" aria-placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="企业中文标签" prop="summary">
              <el-input v-model="company.simplified_tag" aria-placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="企业繁体标签" prop="summary">
              <el-input v-model="company.traditional_tag" aria-placeholder=""></el-input>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="公司LOGO" prop="poster">
              <div style="color: rgba(54, 65, 85, 0.50);font-size: 14px;margin-top: -10px;margin-bottom: 5px">图片建议尺寸为360px * 360px</div>
              <el-upload
                  list-type="picture-card"
                  :action="uploader.url"
                  :accept="'image/*'"
                  :limit="1"
                  :on-preview="handlePosterPreview"
                  :on-remove="handlePosterRemove"
                  :on-success="uploadPosterSuccess"
                  :on-error="uploadPosterError"
                  :file-list="uploader.posterList"
                  :headers="uploader.headers">
                <i class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <el-dialog :visible.sync="uploader.posterVisible">
                <img :src="company.poster" alt="">
              </el-dialog>
            </el-form-item>
          </div>
        </el-col>

      </el-row>

    </el-form>
  </div>
</template>

<script>
import {addCompany, addCompany2, editCompany, editCompany2, getCompany,getCompany2} from "@/api";
import CustomEditor from "@/components/CustomEditor.vue";

export default {
  name: 'CompanyListItem',
  components: {CustomEditor},
  props: {
    uscc: {
      type: String
    },
    close: {
      type: Function
    },
    reload: {
      type: Function
    }
  },
  data() {
    return {
      company: {
        uscc: '',
        name: '',
        poster: '',
        description: '',
        tag: '',
        simplifiedName: '',
        simplifiedDesc: '',
        simplifiedTag: '',
        traditionalName: '',
        traditionalDesc: '',
        traditionalTag: '',
        contact: '',
        contactWay: '',
        tradingCert: '',
      },
      rules: {
        uscc: [{required: true, message: '请输入企业信用代码', trigger: 'blur'}],
        name: {required: true, message: '请输入企业名称', trigger: 'blur'},
      },
      released: false,
      loading: false,
      uploader: {
        url:"/m_api/admin/resources/upload", // 图片上传请求地址,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        posterList: [],
        bannerList: [],
        posterVisible: false,
        bannerVisible: false,
      },
    }
  },
  methods: {
    onSubmit(formName) {
      if (this.uscc) {
        const params = {uscc: this.uscc};
        editCompany2(params, this.company).then(res => {
          if (res) {
            console.log('保存成功, id: ', res.uscc);
            this.$message.success('数据保存成功');
          } else {
            this.$message.warning('返回值异常');
          }
        }).catch(error => {
          this.$message.error("数据保存失败，请稍后重试")
          console.log('#', error);
        });
      } else {
        addCompany2(this.company).then(res => {
          if (res) {
            console.log('保存成功, id: ', res.uscc);
            this.$message.success('数据保存成功');
          } else {
            this.$message.warning('返回值异常');
          }
        }).catch(error => {
          this.$message.error("数据保存失败，请稍后重试")
          console.log('#', error);
        });
      }
      setTimeout(()=>{
        this.reload();
        this.close();
        // this.initCompany();
      }, 1000);
    },
    onClose() {
      this.close();
    },
    getCompanyData(uscc) {
      const params = {
        uscc: uscc
      }
      getCompany2(params).then(res => {
        if (res.uscc) {
          this.company = res;
        } else {
          this.$message.error('未找到USCC为' + uscc + '的企业信息');
        }
      }).catch(error => {
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      });
    },
    initCompany() {
      this.company= {
        uscc: '',
        name: '',
        description: '',
        tag: '',
        simplifiedName: '',
        simplifiedDesc: '',
        simplifiedTag: '',
        traditionalName: '',
        traditionalDesc: '',
        traditionalTag: '',
        contact: '',
        contactWay: '',
        tradingCert: '',
      };
    },
    handlePosterPreview(file) {
      console.log('@poster@', file);
      this.uploader.posterVisible = true;
    },
    handlePosterRemove(file, fileList) {
      this.company.poster = '';
      console.log('@poster@', file, fileList);
    },
    uploadPosterSuccess(res, file) {
      console.log('@poster@: ', res);
      this.company.poster = res.path;
      console.log('@poster@: ', file);
    },
    uploadPosterError() {
      this.$message.error("封面图片上传失败");
    },
  },
  watch: {
    uscc(value) {
      if (value) {
        this.$message.info("切换职位信息：" + value);
        this.getCompanyData(value);
      } else {
        this.initCompany();
        console.log('New life');
      }
    },
  },
  mounted() {
    console.log('@', this.uscc, this.company.uscc);
    // console.log(this.life.id && this.life.id === '');
    if (this.uscc) {
      if (this.company.uscc === '') {
        this.getCompanyData(this.uscc);
      }
    }

    // this.getCompanies();
  },
}
</script>

<style scoped>
@import "@/assets/css/global.css";
.cus-card{
  border-radius: 15px;
  background: #FFF;
  padding: 20px;
  margin-bottom: 20px;
}
.avatar-uploader .el-update {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-update:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.tips {
  line-height: 16px;
}

.b-form .el-form-item__label{
  color: #364155 !important;;
  font-size: 14px !important;;
  font-style: normal !important;;
  font-weight: 600 !important;
  line-height: normal;
  padding: 0px !important;
  margin-top: 0px;
  background: red;
}
</style>
