<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>人才报告</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  添加问题  -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="50%" @click='closeDialog("edit")'>
      <el-form ref="questionForm" :model="question" :rules="rules" size="small" label-width="80px" label-position="top">
        <div>
          <el-row>
            <el-col :span="12">
              <el-form-item label="语言" prop="language">
                <el-select v-model="question.language" placeholder="请选择文档语言">
                  <el-option value="EN" label="英语"></el-option>
                  <el-option value="CN" label="简体中文"></el-option>
                  <el-option value="TC" label="繁体中文"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="对应栏目" prop="topic">
                <el-select v-model="question.topic" allow-create filterable placeholder="请选择栏目">
                  <el-option v-for="item in topicList" :key="item.code" :value="item.code" :label="item.label"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="选项展现方式" prop="pattern">
                <el-select v-model="question.pattern" placeholder="请选择选项展现方式">
                  <el-option value="RADIO" label="单选框"></el-option>
                  <el-option value="CHECKBOX" label="复选框"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="可选数量" prop="limit">
                <el-input-number v-model="question.limit" :min="1" :max="10" label="数量限制"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="序号" prop="sn">
                <el-input-number v-model="question.sn" placeholder="" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="问题名称">
                <el-input v-model="question.title" placeholder="请输入问题名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item >
                <div style="margin-top: 10px">
                  <el-button type="primary" class="cus-save-button" @click="onSubmit('questionForm')">保存</el-button>
                  <el-button type="info" @click='closeDialog("edit")'>取消</el-button>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </el-dialog>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search">
      <el-form-item>
        <el-input size="small" v-model="formInline.keyword" placeholder="请输入关键字"></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="formInline.language" placeholder="请选择语言">
          <el-option value="EN">English</el-option>
          <el-option value="CN">简体中文</el-option>
          <el-option value="TC">繁體中文</el-option>
        </el-select>
        <!--        <el-button size="small" type="primary" style="" icon="el-icon-search" @click="search">搜索</el-button>-->
        <el-button size="small" type="primary" icon="el-icon-plus"  class="cus-add-button" style="margin-left: 10px" @click="handleEdit()">添加</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table
        size="small" highlight-current-row  element-loading-text="加载中……" style="width: 100%;"
        v-loading="loading"
        :data="listData"
    >
      <el-table-column sortable prop="sn" label="序号" width="70"></el-table-column>
      <el-table-column sortable prop="language" label="语言" width="100">
        <template slot-scope="scope">
          {{ getLanguageLabel(scope.row.language) }}
        </template>
      </el-table-column>
      <el-table-column sortable prop="title" label="题目" width="600"></el-table-column>
      <el-table-column sortable prop="limit" label="可选数量" width="100">
      </el-table-column>
      <el-table-column sortable prop="pattern" label="选项展现方式" width="120">
        <template slot-scope="scope">
          {{ getPatternLabel(scope.row.pattern)}}
        </template>
      </el-table-column>
      <el-table-column sortable prop="scenes" label="针对的栏目" width="150">
        <template slot-scope="scope">
          {{ getTopicLabel(scope.row.topic)}}
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" min-width="200">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" @click="handleOptions(scope.$index, scope.row)">编辑选项</el-button>
          <el-button size="mini" type="danger" @click="deleteLife(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <!--    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>-->
    <!-- 编辑选项界面 -->
    <el-drawer :title="title" :visible.sync="drawer" :wrapper-closable="false" size="70%">
      <OptionList :reload="search" :close="changeDrawer" :questionId="selectedQuestionId"></OptionList>
    </el-drawer>
  </div>
</template>

<script>
import {
  addQuestion,
  editQuestion,
  getQuestion,
  listQuestions,
  removeQuestion
} from "@/api";
import {getTopic, topics} from "@/utils/constants";
import OptionList from "@/views/questionnaire/OptionList";
export default {
  name: 'TalentReport',
  data() {
    return {
      loading: false, //是显示加载
      drawer: false,
      selectedQuestionId: null,
      title: '',
      formInline: {
        page: 0,
        size: 10,
        catalog: 'TR',
        keyword: '',
        language: 'EN'
      },
      userparm: [], //搜索权限
      listData: [], //用户数据
      question: {
        id: '',
        sn: NaN,
        language: '',
        catalog: 'TR',
        title: '',
        limit: NaN,
        pattern: '',
        topic: ''
      },
      rules: {
        language: {required: true, message: '请选择语言', trigger: 'blur'},
      },
      topicList: topics,
      editFormVisible: false,
    }
  },
  // 注册组件
  components: {
    OptionList
  },
  /**
   * 创建完毕
   */
  created() {
    this.getdata(this.formInline)
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    getdata(parameter) {
      this.loading = true

      listQuestions(parameter).then(res => {
        this.loading = false;
        this.listData = res;
      }).catch(error => {
        this.loading = false;
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      })

    },

    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage - 1
      this.formInline.size = parm.pageSize
      this.getdata(this.formInline)
    },
    // 搜索事件
    search() {
      this.getdata(this.formInline)
    },
    //显示编辑界面
    handleEdit: function(index, row) {
      console.log('@', index, row);
      if (row !== undefined && row !== 'undefined') {
        this.title = '修改问题信息'
        this.questionId = row.id;
        this.getQuestionData(this.questionId);
      } else {
        this.title = '添加问题信息'
        this.questionId = null;
      }
      this.editFormVisible = true;
    },
    closeDialog(dialog) {
      if (dialog === 'edit') {
        this.editFormVisible = false

      } else if (dialog === 'perm') {
        this.dataAccessshow = false
      } else if (dialog === 'unit') {
        this.unitAccessshow = false
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.questionId) {
            const params = { questionId: this.questionId };
            editQuestion(params, this.question).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.id);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          } else {
            addQuestion(this.question).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.id);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          }
          setTimeout(()=>{
            this.search();
            this.closeDialog('edit');
          }, 1000);
        } else {
          console.log(this.benefit);
          return false;
        }
      });
    },
    handleOptions: function (index, row) {
      if (row !== undefined && row !== 'undefined') {
        this.selectedQuestionId = row.id;
      } else {
        this.selectedQuestionId = null;
      }
      this.drawer = true;
    },
    changeDrawer() {
      this.drawer = !this.drawer;
    },

    // 删除一项生活记录
    deleteLife(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('call remove question api: ', index, row);
        const params = {questionId: row.questionId};
        removeQuestion(params).then(res => {
          setTimeout(() => {
            this.$message.success('该问题已删除');
            this.search();
          }, 1000);
        }).catch(err => {
          this.loading = false
          console.log('Error: ', err);
          this.$message.error('该问题删除失败，请稍后再试！')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    getQuestionData(id) {
      const params = {
        questionId: id
      }
      getQuestion(params).then(res => {
        if (res) {
          this.question = res;
        } else {
          this.$message.error('未找到该问题信息，编号：' + id);
        }
      }).catch(error => {
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      });
    },
    initQuestion() {
      this.question.id = '';
      this.question.sn = NaN;
      this.question.language= '';
      this.question.catalog= 'TR';
      this.question.title='';
      this.question.limit= NaN;
      this.question.pattern= '';
      this.question.topic= '';
    },
    getLanguageLabel(code){
      if (code) {
        switch (code) {
          case 'EN':
            return '英语';
          case 'CN':
            return '简体中文';
          case 'TC':
            return '繁体中文';
        }
      }
    },
    getPatternLabel(code){
      if (code) {
        switch (code) {
          case 'RADIO':
            return '单选框';
          case 'CHECKBOX':
            return '复选框';
          default:
            return '未知';
        }
      }
    },
    getTopicLabel(code){
      if (code) {
        const topic = getTopic(code);
        return topic.label;
      }
    },
  },
  watch: {
    questionId(value) {
      if (value) {
        this.$message.info('你选择的问题编号是:' + value);
        this.getQuestionData(value);
      } else {
        this.initQuestion();
      }
    },
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}
.userRole {
  width: 100%;
}

.el-table .warning-row {
  background-color: oldlace;
}

.el-table .top-row {
  background-color: #f0f9EB;
}
.bread-ban {
  height: 60px;
}
</style>
