<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>港澳专区</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search">
      <el-form-item>
        <el-input
            suffix-icon="el-icon-search"
            size="small" v-model="formInline.keyword"
            @keyup.enter.native="search"
            placeholder="请输入关键字"></el-input>

      </el-form-item>
      <el-form-item>
<!--        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>-->
        <el-button size="small" type="primary" icon="el-icon-plus" class="cus-add-button" @click="handleEdit()">添加新的港澳专区信息</el-button>
<!--        <el-select v-model="formInline.language" placeholder="请选择语言">-->
<!--          <el-option value="EN">English</el-option>-->
<!--          <el-option value="CN">简体中文</el-option>-->
<!--          <el-option value="TC">繁體中文</el-option>-->
<!--        </el-select>-->
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table
        size="small" highlight-current-row  element-loading-text="加载中……" style="width: 100%;"
        :row-class-name = "tableRowClassName"
        v-loading="loading"
        :data="listData"
    >
      <el-table-column align="center" type="selection" width="60"></el-table-column>
      <el-table-column prop="poster" label="封面图片" width="150">
        <template slot-scope="scope">
          <el-image v-if="scope.row.poster" style="width: 100px; height: 100px" :src="scope.row.poster" fit="fill"></el-image>
          <el-empty v-else :image-size="100" style="height: 100px" ></el-empty>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="名称 SC/TC/EN" width="300">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.articleId }}</div>
            <div style="margin-top:10px">{{ scope.row.title.CN }}</div>
            <div>{{ scope.row.title.TC }}</div>
            <div>{{ scope.row.title.EN }}</div>
          </div>
          <div style="margin-top: 10px">
            <div>
              最近更新时间: {{ scope.row.create_date }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column  prop="status" label="状态" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status > 0" type="success">已发布</el-tag>
          <el-tag v-else type="info">未发布</el-tag>
        </template>
      </el-table-column>
      <el-table-column  prop="catalog" label="港澳专区分类" width="300">
        <template slot-scope="scope">
          {{ getCatalogLabel(scope.row.catalog) }}
        </template>
      </el-table-column>
<!--      <el-table-column  prop="talent_category" label="人才类型" width="200">-->
<!--        <template slot-scope="scope">-->
<!--          <div v-for="item in scope.row.talent_category" :key="item" class="talent_category c-c">-->
<!--            <div class="cus-item">{{ item }}</div>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->

      <el-table-column  prop="scenes" label="适用场景" width="200">
        <template slot-scope="scope">
          <div  v-for="item in scope.row.scene" :key="item">
            <div>
              <div class="scenes">{{ item }}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="banner" label="横幅图片" width="200">
        <template slot-scope="scope">
          <el-image v-if="scope.row.banner" style="width: 160px; height: 100px" :src="scope.row.banner" fit="fill"></el-image>
          <el-empty v-else :image-size="160" style="height: 100px" ></el-empty>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" min-width="120">
        <template slot-scope="scope">
          <div><el-button size="mini" class="cus-edit-button" @click="handleEdit(scope.$index, scope.row)">编辑</el-button></div>
          <div style="margin-top: 10px"><el-button size="mini" class="cus-deleted-button" @click="deleteUser(scope.$index, scope.row)">删除</el-button></div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 编辑界面 -->
    <el-drawer :title="title" :visible.sync="drawer" size="70%">
      <BayListItem :reload="search" :close="closeDrawer" :language="formInline.language" :articleId="selectedArticleId"></BayListItem>
    </el-drawer>
  </div>
</template>

<script>
import { listBays, listBays2, removeBay,removeContent2} from "@/api";
import Pagination from '@/components/Pagination'
import {getCategory, getScene, getBayCatalog} from "@/utils/constants";
import BayListItem from "@/views/bay/BayListItem";
export default {
  name: 'BayList',
  data() {
    return {
      nshow: true, //switch开启
      fshow: false, //switch关闭
      loading: false, //是显示加载
      drawer: false,
      selectedArticleId: null,
      title: '',
      formInline: {
        page: 0,
        size: 5,
        keyword: '',
        language: 'EN'
      },
      userparm: [], //搜索权限
      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 0,
        pageSize: 5,
        total: 10
      }
    }
  },
  // 注册组件
  components: {
    BayListItem,
    Pagination
  },
  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.getdata(this.formInline)
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {

    getdata(parameter) {
      this.loading = true

      listBays2(parameter).then(res => {
        this.loading = false;
        if (res.status === 'success') {
          console.log('Get: ', res.totalElements, res.totalPages, res.number, res.size);
          this.listData = res.data;
          this.pageparm.currentPage = res.number + 1;
          this.pageparm.pageSize = res.size;
          this.pageparm.total = res.totalElements;
        } else {
          console.log('@ no data.')
        }
      }).catch(error => {
        this.loading = false;
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      })

    },
    tableRowClassName({row}) {
      // console.log('@', rowIndex, row.status, row.priority);
      if (row.priority === 9) {
        return 'top-row';
      }
      return '';
    },

    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage - 1
      this.formInline.size = parm.pageSize
      this.getdata(this.formInline)
    },
    // 搜索事件
    search() {
      this.getdata(this.formInline)
    },
    //显示编辑界面
    handleEdit: function(index, row) {
      // console.log('@', index, row);
      if (row !== undefined && row !== 'undefined') {
        this.title = '修改港澳专区信息'
        this.selectedArticleId = row.articleId;
      } else {
        this.title = '添加港澳专区信息'
        this.selectedArticleId = null;
      }
      this.drawer = true;
    },
    closeDrawer() {
      // console.log('@ call changeDrawer()');
      this.drawer = !this.drawer;
    },

    // 删除一项港澳专区信息
    deleteUser(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('call remove benefit api: ', index, row);
        const params = {articleId: row.articleId};
        removeContent2(params).then(res => {
          setTimeout(() => {
            this.$message({
              type: 'success',
              message: '该港澳专区信息已删除!'
            });
            this.getdata(this.formInline);
          }, 1000);
        }).catch(err => {
              this.loading = false
              console.log('Error: ', err);
              this.$message.error('该信息删除失败，请稍后再试！')
            })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    getCatalogLabel(code){
      // console.log('#catalog#', code);
      let label = '';
      const catalog = getBayCatalog(code);
      if (catalog) {
        if (this.formInline.language || this.formInline.language === 'CN') label = catalog.cnlabel;
        else if (this.formInline.language === 'TC') label = catalog.cnlabel;
        else label = catalog.label;
      }

      return label;
    },
    getTCLabel(codes){
      let labels = [];
      if (!codes) return labels;
      for (let i in codes) {
        let category = getCategory(codes[i]);
        if (category) {
          if (this.formInline.language || this.formInline.language === 'CN') labels.push(category.cnlabel);
          else if (this.formInline.language === 'TC') labels.push(category.cnlabel);
          else labels.push(category.label);
        }
      }
      return labels.join(",");
    },
    getTSLabel(codes){
      let labels = [];
      if (!codes) return labels;
      if (this.formInline.language || this.formInline.language === 'EN') return codes.join(",");
      for (let i in codes) {
        let catalog = getScene(codes[i]);
        if (catalog) {
          if (this.formInline.language === 'CN') labels.push(catalog.cnlabel);
        }
      }
      return labels.join(",");
    },

  },
}
</script>

<style scoped>
@import "@/assets/css/global.css";
.user-search {
  margin-top: 20px;
}


.article-search {
  margin-top: 20px;
}
.catalog-block{
  border-radius: 6px;
  border: 0.5px solid rgba(54, 65, 85, 0.50);
  background: rgba(54, 65, 85, 0.20);
  display: inline-block;
  padding: 2px 10px;
}
.c-c{
  display: flex;
  align-items: center;
  justify-content: center;
}
.talent_category .cus-item{
  display: inline-block;
  border-radius: 6px;
  border: 0.5px solid #D6776F;
  background: rgba(214, 119, 111, 0.20);
  padding: 2px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.scenes{
  border-radius: 6px;
  border: 0.5px solid #A57392;
  background: rgba(165, 115, 146, 0.20);  padding: 2px 10px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
