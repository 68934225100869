const benefitCatalogs = [
    {"code": "B01", "label": "Personal tax subsidy", "cnlabel": "个税补贴"},
    {"code": "B02", "label": "Qianhai Industry Support", "cnlabel": "前海产业扶持"},
    {"code": "B03", "label": "Professional Qualification", "cnlabel": "职业资格"},
    {"code": "B04", "label": "Foreigner's Work permit", "cnlabel": "外国人工作许可"},
    {"code": "B05", "label": "Foreigner's Residence permit", "cnlabel": "外国人居留许可"},
    {"code": "B06", "label": "List of shortage occupations", "cnlabel": "紧缺职业清单"},
    {"code": "B07", "label": "High-level talent recognition", "cnlabel": "高层次人才认定"},
    // {"code": "B08", "label": "Identity verification", "cnlabel": "身份验证"},
    {"code": "B09", "label": "Social Security Payment", "cnlabel": "社保缴纳"},
    {"code": "B10", "label": "Guangdong Superior Talent Card", "cnlabel": "人才优粤卡服务"},
    {"code": "B11", "label": "Hong Kong & Macao Business Visa", "cnlabel": "港澳商务签注"},
    {"code": "B12", "label": "Financial Assistance for Study Abroad Student", "cnlabel": "出国留学人员资助"},
    {"code": "B13", "label": "Talent Introduction", "cnlabel": "人才引进"},
    {"code": "B14", "label": "Living allowance for doctoral talents", "cnlabel": "博士人才生活补贴"},
    {"code": "B15", "label": "Talent Housing", "cnlabel": "人才住房"},
    {"code": "B16", "label": "Internship, employment, subsidy", "cnlabel": "实习、就业补贴"},
    {"code": "B17", "label": "Business start-up subsidy", "cnlabel": "创业补贴"}
];

const eventCatalogs = [
    {code: "E01", label: "Shenzhen-Hong Kong Special Session", cnlabel: "深港专场"},
    {code: "E02", label: "Talent Cooperation Conference", cnlabel: "人才合作年"},
    {code: "E03", label: "Industry Share Session", cnlabel: "行业分享会"},
    {code: "E04", label: "Wisdom Forum", cnlabel: "智荟论坛"},
    {code: "E05", label: "Chinese Opera", cnlabel: "中国戏曲"},
    {code: "E06", label: "Hong Kong & Macau Youth Training Camp", cnlabel: "港澳青年专训营"},
    {code: "E07", label: "Senior Executives Workshop", cnlabel: "企业高管培训班"},
    {code: "E08", label: "Industry Report Interpretation", cnlabel: "行业报告解读"},
    {code: "E09", label: "Shenzhen-Hong Kong Exchange", cnlabel: "深港交流"},
    {code: "E10", label: "Talent Recruitment", cnlabel: "人才招聘"},
    {code: "E11", label: "Community Intergration Activities", cnlabel: "社群融入活动"},
    {code: "E12", label: "Experience event", cnlabel: "体验活动"},

];

const serviceCatalogs = [
    {code: 'S01', label: 'One-Stop Service Packs', cnlabel: '一站式服务包'},
    {code: 'S02', label: 'Government Services', cnlabel: '政务服务'},
    {code: 'S03', label: 'Business Services', cnlabel: '商务服务'},
    {code: 'S04', label: 'Domestic Services', cnlabel: '生活服务'},
    {code: 'S05', label: 'Volunteer Services', cnlabel: '志愿者服务'},
    {code: 'S06', label: 'One-Stop Service Information', cnlabel: '一站式服务资讯'}
];

const lifeCatalogs = [
    {code: 'L01', label: 'Foods', cnlabel: '饮食'},
    {code: 'L02', label: 'Life Encyclopedia', cnlabel: '生活小百科'},
    {code: 'L03', label: 'Sports & Fitness', cnlabel: '运动'},
    {code: 'L04', label: 'Pets', cnlabel: '宠物'},
    {code: 'L05', label: 'Cultural & Leisure', cnlabel: '文化'},
    {code: 'L06', label: 'Travel', cnlabel: '旅游'},
];

const bayCatalogs = [
    {code: 'H01', label: 'Exclusive', cnlabel: '专区信息'},
    {code: 'H02', label: 'Wish', cnlabel: '前辈寄语'},
    {code: 'H03', label: 'Other', cnlabel: '其他'}
];

const talentCategories = [
    {code: "FT", label: "Foreign Talent", cnlabel: "外籍人才"},
    {code: "HKMT", label: "HK-Macau Talent", cnlabel: "港澳人才"},
    {code: "MT", label: "Mainland Talent", cnlabel: "大陆人才"},
    {code: "RT", label: "Returnees Talent", cnlabel: "海外回国人员"},
];

const talentScenes = [
    {code: 'Work', label: "Work", cnlabel: "工作", tclabel: "工作"},
    {code: 'Study', label: "Study", cnlabel: "学习", tclabel: "學習"},
    {code: 'Invest', label: "Invest", cnlabel: "创业", tclabel: "創業"},
    {code: 'Life', label: "Life", cnlabel: "生活", tclabel: "生活"},
];

const topics = [
    {code: 'Benefit', label: '福利政策'},
    {code: 'Service', label: '服务'},
    {code: 'Event', label: '活动'},
    {code: 'Life', label: '生活'},
    {code: 'HK_MO', label: '港澳专区'},
    {code: 'Us', label: '关于我们'},
    {code: 'ITH', label: '国际人才港'},
    {code: 'All', label: '全部'},
];

const positionCategories = [
    {code: 'C01', label: 'Finance/Economics', cnlabel: '金融/财务/经济类', tclabel: '金融/財務/經濟類'},
    {code: 'C02', label: 'Administration/Human Resource Manager', cnlabel: '行政/人力资源管理类', tclabel: '行政/人力資源管理類'},
    {code: 'C03', label: 'Computer/Internet/IT/Electronics', cnlabel: '计算机/互联网/IT/电子类', tclabel: '計算機/互聯網/IT/電子類'},
    {code: 'C04', label: 'Planning/Architecture', cnlabel: '规划/建筑类', tclabel: '規劃/建築類'},
    {code: 'C05', label: 'Law', cnlabel: '法律类', tclabel: '法律類'},
    {code: 'C06', label: 'Design', cnlabel: '设计类', tclabel: '設計類'},
    {code: 'C07', label: 'Professional Services', cnlabel: '专业服务类', tclabel: '專業服務類'},
    {code: 'C08', label: 'Culture/Education', cnlabel: '文化/教育类', tclabel: '文化/教育類'},
    {code: 'C09', label: 'Advertisement/Media/Creativity/Sports', cnlabel: '广告/传媒/体育类', tclabel: '廣告/傳媒/體育類'},
    {code: 'C10', label: 'Biomedicine/Medical', cnlabel: '生物医药/医疗类', tclabel: '生物醫藥/醫療類'},
    {code: 'C11', label: 'Other', cnlabel: '其他类', tclabel: '其他類'},
];

const positionClassifies = [
    {code: 'Campus', label: 'Campus', cnlabel: '校招', tclabel: '校招'},
    {code: 'Social', label: 'Social', cnlabel: '社招', tclabel: '社招'},
    {code: 'Internship', label: 'Internship', cnlabel: '实习', tclabel: '實習'},
];

const bindingTypes = [
    {code: 'CATALOG', label: '业务范围等二级分类'},
    {code: 'SCENE', label: '人才场景'},
    {code: 'CATEGORY', label: '人才类型'},
    {code: 'TAG', label: '政策专属标签'},
]
const getBenefitCatalog = function (code) {
    const catalog = benefitCatalogs.find((c) => c.code === code);
    return catalog;
}

const getEventCatalog = function (code) {
    const catalog = eventCatalogs.find((c) => code === c.code);
    // console.log('call fun: -->',code, catalog);
    return catalog;
}

const getServiceCatalog = function (code) {
    const catalog = serviceCatalogs.find((c) => code === c.code);
    return catalog;
}

const getLifeCatalog = function (code) {
    const catalog = lifeCatalogs.find((c) => code === c.code);
    return catalog;
}

const getBayCatalog = function (code) {
    const catalog = bayCatalogs.find((c) => code === c.code);
    return catalog;
}

const getCategory = function (code) {
    const category = talentCategories.find((c) => c.code === code);
    return category;
}

const getScene = function (code) {
    const scene = talentScenes.find((c) => c.code === code);
    return scene;
}
const getTopic = function (code) {
    const topic = topics.find((t) => t.code === code);
    return topic;
}

export {
    benefitCatalogs,
    eventCatalogs,
    serviceCatalogs,
    lifeCatalogs,
    bayCatalogs,
    talentCategories,
    talentScenes,
    topics,
    positionCategories,
    positionClassifies,
    bindingTypes,
    getBenefitCatalog,
    getEventCatalog,
    getServiceCatalog,
    getLifeCatalog,
    getBayCatalog,
    getCategory,
    getScene,
    getTopic
}
