<template xmlns:el-col="http://www.w3.org/1999/html">
  <div class="b-form"  style="background: #EFEFEF;padding: 20px">
    <el-form ref="bayForm" :model="bay" :rules="rules" size="small" label-width="80px" label-position="top">


      <el-row gutter="20">
        <el-col :span="16">
          <div class="cus-card">
            <el-form-item label="简体中文标题" prop="title">
              <el-input v-model="bay.title.CN" aria-placeholder="e.g. 深圳市境外高端人才和紧缺人才个人所得税财政补贴"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文标题" prop="title">
              <el-input v-model="bay.title.TC" aria-placeholder="e.g.  深圳市境外高端人才和緊缺人才個人所得稅財政補貼"></el-input>
            </el-form-item>

            <el-form-item label="English标题" prop="title">
              <el-input v-model="bay.title.EN" aria-placeholder="e.g.  Individual Income Tax Subsidies for Overseas High-end Talents and Talents in Short Supply in Shenzhen City"></el-input>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文摘要" prop="summary">
              <el-input v-model="bay.summary.CN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文摘要" prop="summary">
              <el-input v-model="bay.summary.TC" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>

            <el-form-item label="English摘要" prop="summary">
              <el-input v-model="bay.summary.EN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文正文内容" prop="content">
              <CustomEditor ref="quillEditor_CN" :value="htmlContent.CN" toref="quill_CN" :editable="editable"/>
            </el-form-item>

            <el-form-item label="繁体中文正文内容" prop="content">
              <CustomEditor ref="quillEditor_TC" :value="htmlContent.TC" toref="quill_TC" :editable="editable"/>
            </el-form-item>

            <el-form-item label="English正文内容" prop="content">
              <CustomEditor ref="quillEditor_EN" :value="htmlContent.EN" toref="quill_EN" :editable="editable"/>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文搜索引擎元描述" prop="meta_desc">
              <el-input v-model="bay.meta_desc.CN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文搜索引擎元描述" prop="meta_desc">
              <el-input v-model="bay.meta_desc.TC" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>

            <el-form-item label="English搜索引擎元描述" prop="meta_desc">
              <el-input v-model="bay.meta_desc.EN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
          </div>

        </el-col>

        <el-col :span="8">
          <div style="margin-top: -40px;position: fixed;top: 60px;right: 80px;z-index: 99999">
            <el-form-item >
              <el-button class="cus-save-button" @click="onSubmit('bayForm')">保存</el-button>
<!--              <el-button type="info" @click="onClose()">取消</el-button>-->
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="发布状态" prop="status">
              <el-switch v-model="isRelease" @change="setRelease" active-text="前端可见" active-color="#000000"	 inactive-text="已隐藏"/>
            </el-form-item>
            <el-form-item label="排序" prop="status">
              <el-switch v-model="isTop" @change="setTop" active-color="#000000" active-text="置顶" inactive-text="常规"/>
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="专区分类" prop="catalog">
              <el-select v-model="bay.catalog" allow-create filterable placeholder="请选择专区分类">
                <el-option v-for="item in bayCatalogs" :key="item.code" :value="item.code" :label="item.cnlabel"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="人才场景" prop="scenes">
              <el-select v-model="bay.scenes" multiple placeholder="请选择人才场景">
                <el-option value="Work" label="工作"></el-option>
                <el-option value="Study" label="学习"></el-option>
                <el-option value="Invest" label="创业"></el-option>
                <el-option value="Life" label="置业"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="封面图片" prop="poster">
              <div style="color: rgba(54, 65, 85, 0.50);font-size: 14px;margin-top: -10px;margin-bottom: 5px">图片建议尺寸为360px * 360px</div>
              <el-upload
                  list-type="picture-card"
                  :action="uploader.url"
                  :accept="'image/*'"
                  :limit="1"
                  :on-preview="handlePosterPreview"
                  :on-remove="handlePosterRemove"
                  :on-success="uploadPosterSuccess"
                  :on-error="uploadPosterError"
                  :file-list="uploader.posterList"
                  :headers="uploader.headers">
                <i class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <el-dialog :visible.sync="uploader.posterVisible">
                <img :src="bay.poster" alt="">
              </el-dialog>
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="横幅图片" prop="banner">
              <div style="color: rgba(54, 65, 85, 0.50);font-size: 14px;margin-top: -10px;margin-bottom: 5px">图片建议尺寸为1255px * 400px</div>
              <el-upload
                  list-type="picture-card"
                  :action="uploader.url"
                  :accept="'image/*'"
                  :limit="1"
                  :on-preview="handleBannerPreview"
                  :on-remove="handleBannerRemove"
                  :on-success="uploadBannerSuccess"
                  :on-error="uploadBannerError"
                  :file-list="uploader.bannerList"
                  :headers="uploader.headers">
                <i class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <el-dialog :visible.sync="uploader.bannerVisible">
                <img :src="bay.banner" alt="">
              </el-dialog>
            </el-form-item>
          </div>
        </el-col>

      </el-row>



<!--      <div>-->
<!--        <el-row>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="语言" prop="language">-->
<!--              <el-select v-model="bay.language" :disabled="lockedLanguage" placeholder="请选择文档语言">-->
<!--                <el-option value="EN" label="英语"></el-option>-->
<!--                <el-option value="CN" label="简体中文"></el-option>-->
<!--                <el-option value="TC" label="繁体中文"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="专区分类" prop="catalog">-->
<!--              <el-select v-model="bay.catalog" allow-create filterable placeholder="请选择专区分类">-->
<!--                <el-option v-for="item in bayCatalogs" :key="item.code" :value="item.code" :label="item.cnlabel"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item>-->
<!--              <el-switch v-model="isTop" @change="setTop" active-text="置顶" />-->
<!--              <el-divider direction="vertical"/>-->
<!--              <el-switch v-model="isRelease" @change="setRelease" active-text="已发布"/>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="封面图片" prop="poster">-->
<!--              <el-upload-->
<!--                  list-type="picture-card"-->
<!--                  :action="uploader.url"-->
<!--                  :accept="'image/*'"-->
<!--                  :limit="1"-->
<!--                  :on-preview="handlePosterPreview"-->
<!--                  :on-remove="handlePosterRemove"-->
<!--                  :on-success="uploadPosterSuccess"-->
<!--                  :on-error="uploadPosterError"-->
<!--                  :file-list="uploader.posterList"-->
<!--                  :headers="uploader.headers">-->
<!--                <i class="el-icon-plus avatar-uploader-icon" />-->
<!--              </el-upload>-->
<!--              <el-dialog :visible.sync="uploader.posterVisible">-->
<!--                <img :src="bay.poster" alt="">-->
<!--              </el-dialog>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="横幅图片" prop="banner">-->
<!--              <el-upload-->
<!--                  list-type="picture-card"-->
<!--                  :action="uploader.url"-->
<!--                  :accept="'image/*'"-->
<!--                  :limit="1"-->
<!--                  :on-preview="handleBannerPreview"-->
<!--                  :on-remove="handleBannerRemove"-->
<!--                  :on-success="uploadBannerSuccess"-->
<!--                  :on-error="uploadBannerError"-->
<!--                  :headers="uploader.headers">-->
<!--                <i class="el-icon-plus" />-->
<!--              </el-upload>-->
<!--              <el-dialog :visible.sync="uploader.bannerVisible">-->
<!--                <img :src="bay.banner" alt="">-->
<!--              </el-dialog>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item>-->
<!--              <el-switch v-model="editable" active-text="是否编辑内容" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="24">-->
<!--            <el-form-item label="标题" prop="title">-->
<!--              <el-input v-model="bay.title" aria-placeholder="请输入标题"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="24">-->
<!--            <el-form-item label="摘要" prop="summary">-->
<!--              <el-input v-model="bay.summary" type="textarea" :rows="2" aria-placeholder="请输入摘要"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="24">-->
<!--            <el-form-item label="内容" prop="content">-->
<!--              <CustomEditor ref="quillEditor" :value="bay.content" :editable="editable"/>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="24">-->
<!--            <el-form-item >-->
<!--              <el-button type="primary" @click="onSubmit('bayForm')">保存</el-button>-->
<!--              <el-button type="info" @click="onClose()">取消</el-button>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </div>-->

    </el-form>
  </div>
</template>

<script>
import CustomEditor from "@/components/CustomEditor";
import {getBay, addBay, editBay, editBay2, getBay2, addContent2} from "@/api";
import { bayCatalogs } from "@/utils/constants";

export default {
  name: 'BayListItem',
  components: {CustomEditor},
  props: {
    articleId: {
      type: String
    },
    language: {
      type: String
    },
    close: {
      type: Function
    },
    reload: {
      type: Function
    }
  },
  data() {
    return {
      bay: {
        id: '',
        language: 'EN',
        title: {CN: '', TC: '', EN: ''},
        banner: '',
        poster: '',
        meta_desc: {CN: '', TC: '', EN: ''},
        summary: {CN: '', TC: '', EN: ''},
        content: '',
        catalog: '',
        scenes: [],
        categories: ['HKMT'],
        department: '',
        priority: 1,
        bay: 1,
      },
      htmlContent: {CN: '', TC: '', EN: ''},
      rules: {
        // language: [{required: true, message: '请选择语言', trigger: 'blur'}],
        // catalog: [{required: true, message: '请选择文档分类', trigger: 'blur'}],
        // title: [
        //   {required: true, message: '请输入文档标题', trigger: 'blur'},
        //   {min: 1, max: 256, message: '标题长度在5 到 256个字符', trigger: 'blur'},
        // ],
        // summary: [{max: 1000, message: '标题长度小于1000个字符', trigger: 'blur'},
        // ],
      },
      uploader: {
        url:"/m_api/admin/resources/upload", // 图片上传请求地址,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        posterList: [],
        bannerList: [],
        posterVisible: false,
        bannerVisible: false,
      },
      lockedLanguage: false,
      isTop: false,
      isRelease: false,
      editable: true,
      contentHeight: 520,
      bayCatalogs: bayCatalogs
    }
  },
  methods: {
    setTop() {
      this.bay.priority = this.isTop ?  9 : 1;
    },
    setRelease() {
      this.bay.status = this.isRelease ?  1: 0;
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.articleId) {
            console.log('#, ', this.bay.content);
            if (this.editable) {
              console.log('##, ', this.htmlContent);
              console.log('###, ', this.bay.content);
            }
            this.bay.content = this.htmlContent;
            const params = { articleId: this.articleId };
            editBay2(params, this.bay).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.id);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          } else {
            console.log('#, ', this.bay.content);
            if (this.editable) {
              console.log('##, ', this.htmlContent);
              // this.bay.content = this.htmlContent.replace(/<p><br><\/p>/g,"").replace(/<div><br><\/div>/g,"");
              console.log('###, ', this.bay.content);
            }
            this.bay.content = this.htmlContent;
            this.bay.topic = "HK_MO";
            this.bay.topic_title = "H";
            addContent2(this.bay).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.id);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          }
          setTimeout(()=>{
            this.reload();
            this.close();
            this.editable = true;
          }, 1000);
        } else {
          console.log(this.bay);
          return false;
        }
      });
    },
    onClose() {
      // console.log('$ call onCancel');
      this.editable = true;
      this.close();
    },
    getBayData(articleId, language) {
      const params = {
        articleId,
        language
      }
      getBay2(params).then(res => {
        if (res.articleId) {
          this.bay = res;
          this.bay.id = res.articleId;
          this.htmlContent = res.content;
          this.isTop = this.bay.priority === 9;
          this.isRelease = this.bay.status > 0;
          this.uploader.posterList = [];
          if (this.bay.poster) {
            this.uploader.posterList.push({name: 'poster', url: this.bay.poster});
          }
          this.uploader.bannerList = [];
          if (this.bay.banner) {
            this.uploader.bannerList.push({name: 'banner', url: this.bay.banner});
          }
          this.lockedLanguage = true;
        } else {
          this.$message.error('该文档数据不存在，编号：' + articleId);
        }
      }).catch(error => {
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      });
    },
    changeContentCN(value) {
      console.log('@ call changeContent' );
      this.htmlContent.CN = value;
    },
    changeContentTC(value) {
      console.log('@ call changeContent' );
      this.htmlContent.TC = value;
    },
    changeContentEN(value) {
      console.log('@ call changeContent' );
      this.htmlContent.EN = value;
    },
    initBay() {
      this.bay = {
        id: '',
        language: 'EN',
        title:  {CN: '', TC: '', EN: ''},
        poster: '',
        banner: '',
        summary: {CN: '', TC: '', EN: ''},
        content: {CN: '', TC: '', EN: ''},
        meta_desc: {CN: '', TC: '', EN: ''},
        catalog: '',
        scenes: [],
        categories: [],
        department: '',
        priority: 1,
        bay: false,
        status: 0,
      };
      this.uploader.posterList = [];
      this.uploader.bannerList = [];
      this.htmlContent = {CN: '', TC: '', EN: ''};
    },
    handlePosterPreview(file) {
      console.log('@poster@', file);
      this.uploader.posterVisible = true;
    },
    handlePosterRemove(file, fileList) {
      this.bay.poster = '';
      console.log('@poster@', file, fileList);
    },
    uploadPosterSuccess(res, file) {
      console.log('@poster@: ', res);
      this.bay.poster = res;
      console.log('@poster@: ', file);
    },
    uploadPosterError() {
      this.$message.error("封面图片上传失败");
    },
    handleBannerPreview(file) {
      console.log('@banner@', file);
      this.uploader.bannerVisible = true;
    },
    handleBannerRemove(file, fileList) {
      console.log('@banner@', file, fileList);
      this.bay.banner='';
    },
    uploadBannerSuccess(res, file) {
      console.log('@banner@: ', res);
      this.bay.banner = res;
      console.log('@banner@: ', file);
    },
    uploadBannerError() {
      this.$message.error("横幅图片上传失败");
    },
  },
  watch: {
    articleId(value) {
      if (value) {
        console.log('Edit bay,', value);
        this.getBayData(value, this.bay.language);
      } else {
        this.lockedLanguage = false;
        this.initBay();
        console.log('New bay');
      }
    },
    language(value) {
      if (value) {
        this.getBayData(this.articleId, value);
      }
    }
  },
  mounted() {
    console.log('@', this.articleId, this.bay.id);
    // console.log(this.bay.id && this.bay.id === '');
    if (this.articleId) {
      this.bay.language = this.language;
      if (this.bay.id === '') {
        this.getBayData(this.articleId, this.language);
      }
    }

    this.$refs.quillEditor_CN.$on("input", this.changeContentCN);
    this.$refs.quillEditor_TC.$on("input", this.changeContentTC);
    this.$refs.quillEditor_EN.$on("input", this.changeContentEN);

  },
  beforeDestroy() {
    this.$off('input');
    this.initBay();
  }
}
</script>

<style scoped>
.cus-card{
  border-radius: 15px;
  background: #FFF;
  padding: 20px;
  margin-bottom: 20px;
}
.avatar-uploader .el-update {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-update:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.tips {
  line-height: 16px;
}

.b-form .el-form-item__label{
  color: #364155 !important;;
  font-size: 14px !important;;
  font-style: normal !important;;
  font-weight: 600 !important;
  line-height: normal;
  padding: 0px !important;
  margin-top: 0px;
  background: red;
}
</style>
