<template>
  <div>CostCalculator page</div>
</template>

<script>
export default {
  name: "CostCalculator"
}
</script>

<style scoped>

</style>