<template>
    <el-drawer
        :title="title"
        :visible.sync="childDrawer"
        :before-close="handleClose"
        direction="rtl"
        size="50%"
    >
      <el-button @click="tagFormVisible = true">添加标签</el-button>
      <el-table
          size="small" highlight-current-row  border element-loading-text="加载中……" style="width: 100%;"
          :data="listData"
      >
        <el-table-column sortable prop="group" label="标签组" width="120"></el-table-column>
        <el-table-column sortable prop="name" label="标签" width="500">
          <template slot-scope="scope">
            <el-tag :key="scope.row.id" closable @close="onDelete(scope.row.id)">{{scope.row.cnLabel}}</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="添加标签" :visible.sync="tagFormVisible">
        <el-form :model="tag">
          <el-form-item label="标签组" :label-width="formLabelWidth">
            <el-cascader
                v-model="tag.tagId"
                :options="tagOptions"
                :show-all-levels="false"
                :props="{emitPath: false, value: 'name'}"
                clearable>

            </el-cascader>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="tagFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addArticleTag">确 定</el-button>
        </div>
      </el-dialog>
    </el-drawer>
</template>

<script>
import {listArticleTags, removeArticleTag, listTagOptions, addArticleTag} from "@/api";

export default {
  name: "ArticleTagList",
  props: {
    title: {
      type: String,
    },
    articleId: {
      type: String,
    },
  },
  data() {
    return {
      childDrawer: true,
      tag: {
        tagId: NaN,
        articleId: '',
      },
      listData: [],
      tagOptions: [],
      formLabelWidth: "80px",
      tagFormVisible: false,
    };
  },
  mounted() {
    if (this.articleId) {
      this.getData(this.articleId);
    }
    this.getTagOptions();
  },
  watch: {
    articleId(value) {
      if (value) {
        this.getData(value);
      }
    },
  },
  methods: {
    close() {
      // this.childDrawer = false;
      this.$emit('closeTagDrawer', false);
    },
    open() {
      this.innerDrawer = true;
    },
    handleClose() {
      // this.$confirm('还有未保存的工作哦确定关闭吗？')
      //     .then(_ => {
      //       done();
      //       this.close();
      //     })
      //     .catch(_ => {});
      this.close();
    },
    getData(articleId) {
      if (!this.articleId) {
        this.$message.warning('文档编号为空');
      } else {
        const params = {articleId: articleId}
        listArticleTags(params).then(res => {
          if(res) {
            this.listData = res;
          } else {
            this.$message.warning('该文档未添加标签');
          }
        }).catch(error => {
          console.log('#Get article tags error:', error);
          this.$message.error('获取标签列表错误');
        })
      }
    },
    getTagOptions() {
      listTagOptions({}).then(res => {
        this.tagOptions = res;
      }).catch(error => {
        console.log('#Get group tags error:', error);
        this.$message.error('标签级联数据获取失败');
      })
    },
    addArticleTag() {
      if (this.tag.tagId) {
        this.tag.articleId = this.articleId;
        this.tag.tagId = Number(this.tag.tagId);
        console.log('add tag: ', this.tag);
        addArticleTag(this.tag).then(res => {
          this.listData.push(res);
          this.$message.success("标签已添加");
        }).catch(error => {
          console.log('Add tag error: ', error);
          this.$message.error("标签添加失败");
        })
      }
      this.tagFormVisible = false;
    },
    onDelete(id) {
      console.log(id);
    }
  },
}
</script>

<style scoped>

</style>