import axios from 'axios';

// 登录请求方法
const loginRequest = (method, url, params) => {
    return axios({
        method: method,
        url: url,
        data: params,
    }).then((res => res.data))
}

const httpGet = (url, params) => {
    return axios({
        method: 'get',
        url: url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        params: params,
    }).then((res => res.data))
}

const httpDelete = (url, params) => {
    return axios({
        method: 'delete',
        url: url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        params: params,
    }).then((res => res.data))
}

const download = (url, params) => {
    return axios({
        method: 'get',
        url: url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        params: params,
        responseType: 'blob',
    })
}

const request = (method, url, params, data) => {
    return axios({
        method: method,
        url: url,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        params: params,
        data: JSON.stringify(data),
    }).then((res => res.data))
}

// 通用公用方法
const req = (method, url, params) => {
    return axios({
        method: method,
        url: url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.getItem('logintoken')
        },
        data: params,
        traditional: true,
        transformRequest: [
            function(data) {
                let ret = ''
                for (let it in data) {
                    ret +=
                        encodeURIComponent(it) +
                        '=' +
                        encodeURIComponent(data[it]) +
                        '&'
                }
                return ret
            }
        ]
    }).then(res => res.data);
};

export {
    req,
    request,
    loginRequest,
    httpGet,
    httpDelete,
    download,
}