<template xmlns:el-col="http://www.w3.org/1999/html">
  <div class="b-form"  style="background: #EFEFEF;padding: 20px">
    <el-form ref="tagForm" :model="tag" :rules="rules" size="small" label-width="80px" label-position="top">


      <el-row gutter="20">
        <el-col :span="16">
          <div class="cus-card">
            <el-form-item label="名称" prop="name">
              <el-input v-model="tag.name" placeholder="请输入标签名称"></el-input>
            </el-form-item>
            <el-form-item label="英文描述" prop="enLabel">
              <el-input v-model="tag.enLabel" aria-placeholder="请输入英文描述"></el-input>
            </el-form-item>
            <el-form-item label="简体中文描述" prop="enLabel">
              <el-input v-model="tag.cnLabel" aria-placeholder="请输入简体中文描述"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文描述" prop="enLabel">
              <el-input v-model="tag.tcLabel" aria-placeholder="请输入繁体中文描述"></el-input>
            </el-form-item>
          </div>
        </el-col>

        <el-col :span="8">
          <div style="margin-top: -40px;position: fixed;top: 60px;right: 80px;z-index: 99999">
            <el-form-item >
              <!--              <el-button @click="onRefresh">刷新</el-button>-->
              <el-button class="cus-save-button" @click="onSubmit('tagForm')">保存</el-button>
              <!--              <el-button type="info" @click="onClose()">取消</el-button>-->
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="标签组" prop="group">
              <el-select v-model="tag.group" allow-create filterable placeholder="请选择标签组">
                <el-option v-for="item in tagGroups" :key="item" :value="item" :label="item"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>

    </el-form>
  </div>
</template>

<script>
import {getTag, addTag, editTag, listTagGroups} from "@/api";
import CustomEditor from "@/components/CustomEditor.vue";

export default {
  name: 'TagListItem',
  components: {CustomEditor},
  props: {
    tagId: {
      type: Number
    },
    close: {
      type: Function
    },
    reload: {
      type: Function
    }
  },
  data() {
    return {
      tag: {
        id: '',
        name: '',
        group: '',
        enLabel: '',
        cnLabel: '',
        tcLabel: ''
      },
      rules: {
        name: [{required: true, message: '请输入标签名称', trigger: 'blur'}],
        group: [{required: true, message: '请选择标签组名称', trigger: 'blur'}],
      },
      tagGroups: []
    }
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.tagId) {
            const params = { tagId: this.tagId };
            editTag(params, this.tag).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.id);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          } else {
            addTag(this.tag).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.id);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          }
          setTimeout(()=>{
            this.reload();
            this.close();
            this.editorStatus = false;
          }, 1000);
        } else {
          console.log(this.service);
          return false;
        }
      });
    },
    onClose() {
      this.close();
    },
    getTagData(tagId) {
      const params = {
        tagId
      }
      getTag(params).then(res => {
        if (res.id) {
          this.tag = res;
          console.log('http response: ', this.tag);
        } else {
          this.$message.error('该文档数据不存在，编号：' + tagId);
        }
      }).catch(error => {
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      });
    },
    getTagGroup() {
      listTagGroups({}).then(res => {
        this.tagGroups = res;
      }).catch(error => {
        console.log("#get tag groups error: ", error);
        this.$message.error('标签组加载失败, 请稍后重试!')
      });
    },
    initTag() {
      this.tag = {
        id: '',
        name: '',
        group: '',
        enLabel: '',
        cnLabel: '',
        tcLabel: ''
      }
    },
  },
  watch: {
    tagId(value) {
      if (value) {
        this.getTagData(value);
      } else {
        this.initService();
      }
    },
  },
  mounted() {
    console.log('@', this.tagId, this.tag.id);
    this.getTagGroup();
    if (this.tagId) {
      if (this.tag.id === '') {
        this.getTagData(this.tagId);
      }
    }
  },

}
</script>

<style scoped>
.cus-card{
  border-radius: 15px;
  background: #FFF;
  padding: 20px;
  margin-bottom: 20px;
}
.avatar-uploader .el-update {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-update:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.tips {
  line-height: 16px;
}

.b-form .el-form-item__label{
  color: #364155 !important;;
  font-size: 14px !important;;
  font-style: normal !important;;
  font-weight: 600 !important;
  line-height: normal;
  padding: 0px !important;
  margin-top: 0px;
  background: red;
}
</style>
