<template xmlns:el-col="http://www.w3.org/1999/html">
  <div class="b-form"  style="background: #EFEFEF;padding: 20px">
    <el-form ref="positionForm" :model="position" :rules="rules" size="small" label-width="80px" label-position="top">


      <el-row gutter="20">
        <el-col :span="16">
          <div class="cus-card">
            <el-form-item label="职位名称" prop="title">
              <el-input v-model="position.name" placeholder="请输入职位名称"></el-input>
            </el-form-item>
            <el-form-item label="工作内容" prop="detail">
              <el-input v-model="position.detail" type="textarea" :rows="13" placeholder="请输入工作内容"></el-input>
            </el-form-item>
            <el-form-item label="岗位要求" prop="requirement">
              <el-input v-model="position.requirement" type="textarea" :rows="13" placeholder="请输入岗位要求"></el-input>
            </el-form-item>
            <el-form-item label="员工福利" prop="benefits">
              <el-input v-model="position.benefits" type="textarea" :rows="13" placeholder="请输入员工福利"></el-input>
            </el-form-item>
          </div>


        </el-col>

        <el-col :span="8">
          <div style="margin-top: -40px;position: fixed;top: 60px;right: 80px;z-index: 99999">
            <el-form-item >
              <!--              <el-button @click="onRefresh">刷新</el-button>-->
              <el-button class="cus-save-button" @click="onSubmit('positionForm')">保存</el-button>
              <!--              <el-button type="info" @click="onClose()">取消</el-button>-->
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="语言" prop="language">
              <el-select v-model="position.language" placeholder="请选择文档语言">
                <el-option value="EN" label="英语"></el-option>
                <el-option value="CN" label="简体中文"></el-option>
                <el-option value="TC" label="繁体中文"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职位类型" prop="catalog">
              <el-select v-model="position.classify" allow-create filterable placeholder="请选择职位类型">
                <el-option v-for="item in classifies" :key="item.code"
                           :value="item.code"
                           :label="position.language === 'CN' ? item.cnlabel : position.language === 'TC' ? item.tclabel : item.label">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职位分类" prop="catalog">
              <el-select v-model="position.category" allow-create filterable placeholder="请选择职位分类">
                <el-option v-for="item in categories" :key="item.code"
                           :value="position.language === 'CN' ? item.cnlabel : position.language === 'TC' ? item.tclabel : item.label"
                           :label="position.language === 'CN' ? item.cnlabel : position.language === 'TC' ? item.tclabel : item.label">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="职位状态">
              <el-checkbox v-model="position.always" label="长期招聘" />
            </el-form-item>
<!--            <el-form-item label="发布状态">-->
<!--              <el-switch v-model="released" :change="setRelease" active-text="已发布" />-->
<!--            </el-form-item>-->
            <el-form-item label="热点职位">
              <el-switch v-model="position.hotspot" active-text="已设置" />
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="招聘人数" prop="employ">
              <el-tooltip class="item" effect="dark" content="招聘人数不限制时请设为0" placement="top-start">
                <el-input-number v-model="position.employ"></el-input-number>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="薪资上限" prop="salaryTo">
              <el-tooltip class="item" effect="dark" content="面议时上限值请设为0" placement="top-start">
                <el-input-number v-model="position.salary_to" :step="1000"></el-input-number>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="薪资下限" prop="salaryFrom">
              <el-tooltip class="item" effect="dark" content="面议时下限值请设为0" placement="top-start">
                <el-input-number v-model="position.salary_from" :step="1000"></el-input-number>
              </el-tooltip>
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="公司名称" prop="uscc">
              <el-select v-model="position.uscc" filterable remote reserve-keyword
                         placeholder="请输入关键字"
                         :remote-method="getCompanies"
                         :loading="loading">
                <el-option
                    v-for="item in companies"
                    :key="item.uscc"
                    :label="item.simplified_name"
                    :value="item.uscc">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工作地点" prop="workplace">
              <el-input v-model="position.workplace" placeholder="请输入工作地点" />
            </el-form-item>
            <el-form-item label="所在省" prop="province">
              <el-input v-model="position.province" placeholder="请输入工作地点（省）"/>
            </el-form-item>
            <el-form-item label="所在市" prop="city">
              <el-input v-model="position.city" placeholder="请输入工作地点（市）"/>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {addPosition, addPosition2, editPosition, editPosition2, getCompanyList, getCompanyList2, getPosition, getPosition2} from "@/api";
import {positionCategories, positionClassifies} from "@/utils/constants";
import CustomEditor from "@/components/CustomEditor.vue";

export default {
  name: 'PositionListItem',
  components: {CustomEditor},
  props: {
    positionId: {
      type: String
    },
    close: {
      type: Function
    },
    reload: {
      type: Function
    }
  },
  data() {
    return {
      position: {
        id: '',
        language: '',
        name: '',
        usc: '',
        category: '',
        classify: '',
        workplace: '',
        province: '',
        city: '',
        salaryFrom: 0,
        salaryTo: 0,
        employ: 1,
        detail: '',
        requirement: '',
        benefits: '',
        status: 0,
        always: false,
        hotspot: false,
      },
      rules: {
        language: [{required: true, message: '请选择语言', trigger: 'blur'}],
        name: {required: true, message: '请输入职位名称', trigger: 'blur'},
        uscc: {required: true, message: '请选择招聘企业', trigger: 'blur'},
      },
      categories: [],
      classifies: [],
      companies: [],
      released: false,
      loading: false,
    }
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.positionId) {
            const params = {id: this.positionId};
            editPosition2(params, this.position).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.id);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          } else {
            addPosition2(this.position).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.id);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          }
          setTimeout(()=>{
            this.reload();
            this.close();
          }, 1000);
        } else {
          console.log(this.position);
          return false;
        }
      });
    },
    onClose() {
      this.close();
    },
    getPositionData(positionId) {
      const params = {
        id: positionId
      }
      getPosition2(params).then(res => {
        if (res.id) {
          this.position = res;
          this.released = this.position.status > 0;
        } else {
          this.$message.error('未找到ID为' + positionId + '的职位信息');
        }
      }).catch(error => {
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      });
    },
    initPosition() {
      this.position = {
        id: '',
        language: '',
        name: '',
        category: '',
        classify: '',
        workplace: '',
        province: '',
        city: '',
        salaryFrom: NaN,
        salaryTo: NaN,
        employ: 1,
        detail: '',
        requirement: '',
        benefits: '',
        status: 0,
        always: false,
        hotspot: false,
      };
    },
    getCompanies(keyword) {
      this.loading = true;
      let params = {};
      if (keyword) params = {keyword: keyword};
      getCompanyList2(params).then(res => {
        this.loading = false;
        if (res) {
          this.companies = res;
        } else {
          this.companies = [{uscc: "", simplifiedName: '为获取到数据'}];
        }
      }).catch(error => {
        console.log("Fetch companies error: ", error)
        this.$message.error("企业信息获取失败");
      });
    }
  },
  watch: {
    positionId(value) {
      if (value) {
        this.$message.info("切换职位信息：" + value);
        this.getPositionData(value);
      } else {
        this.initPosition();
        console.log('New life');
      }
    },
    released(value) {
      if (value) {
        this.position.status = 1;
      } else {
        this.position.status = 0;
      }
    }
  },
  mounted() {
    this.categories = positionCategories;
    this.classifies = positionClassifies;
    this.getCompanies();

    console.log('@', this.positionId, this.position.id);
    // console.log(this.life.id && this.life.id === '');
    if (this.positionId) {
      if (this.position.id === '') {
        this.getPositionData(this.positionId);
      }
    }

    // this.getCompanies();
  },
}
</script>

<style scoped>
.cus-card{
  border-radius: 15px;
  background: #FFF;
  padding: 20px;
  margin-bottom: 20px;
}
.avatar-uploader .el-update {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-update:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.tips {
  line-height: 16px;
}

.b-form .el-form-item__label{
  color: #364155 !important;;
  font-size: 14px !important;;
  font-style: normal !important;;
  font-weight: 600 !important;
  line-height: normal;
  padding: 0px !important;
  margin-top: 0px;
  background: red;
}
</style>
