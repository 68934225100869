<template xmlns:el-col="http://www.w3.org/1999/html">
  <div class="b-form"  style="background: #EFEFEF;padding: 20px">
    <el-form ref="serviceForm" :model="service" :rules="rules" size="small" label-width="80px" label-position="top">


      <el-row gutter="20">
        <el-col :span="16">
          <div class="cus-card">
            <el-form-item label="简体中文标题" prop="title">
              <el-input v-model="service.title.CN" aria-placeholder="e.g. 深圳市境外高端人才和紧缺人才个人所得税财政补贴"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文标题" prop="title">
              <el-input v-model="service.title.TC" aria-placeholder="e.g.  深圳市境外高端人才和緊缺人才個人所得稅財政補貼"></el-input>
            </el-form-item>

            <el-form-item label="English标题" prop="title">
              <el-input v-model="service.title.EN" aria-placeholder="e.g.  Individual Income Tax Subsidies for Overseas High-end Talents and Talents in Short Supply in Shenzhen City"></el-input>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文摘要" prop="summary">
              <el-input v-model="service.summary.CN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文摘要" prop="summary">
              <el-input v-model="service.summary.TC" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>

            <el-form-item label="English摘要" prop="summary">
              <el-input v-model="service.summary.EN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文正文内容" prop="content">
              <CustomEditor ref="quillEditor_CN" :value="htmlContent.CN" toref="quill_CN" :editable="editable"/>
            </el-form-item>

            <el-form-item label="繁体中文正文内容" prop="content">
              <CustomEditor ref="quillEditor_TC" :value="htmlContent.TC" toref="quill_TC" :editable="editable"/>
            </el-form-item>

            <el-form-item label="English正文内容" prop="content">
              <CustomEditor ref="quillEditor_EN" :value="htmlContent.EN" toref="quill_EN" :editable="editable"/>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文搜索引擎元描述" prop="meta_desc">
              <el-input v-model="service.meta_desc.CN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文搜索引擎元描述" prop="meta_desc">
              <el-input v-model="service.meta_desc.TC" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>

            <el-form-item label="English搜索引擎元描述" prop="meta_desc">
              <el-input v-model="service.meta_desc.EN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
          </div>

        </el-col>

        <el-col :span="8">
          <div style="margin-top: -40px;position: fixed;top: 60px;right: 80px;z-index: 99999">
            <el-form-item >
              <el-button type="primary" class="cus-save-button" @click="onSubmit('serviceForm')">保存</el-button>
<!--              <el-button type="info" @click="onClose()">取消</el-button>-->
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="发布状态" prop="status">
              <el-switch v-model="isRelease" @change="setRelease" active-text="前端可见" active-color="#000000"	 inactive-text="已隐藏"/>
            </el-form-item>
            <el-form-item label="排序" prop="status">
              <el-switch v-model="isTop" @change="setTop" active-color="#000000" active-text="置顶" inactive-text="常规"/>
            </el-form-item>
            <el-form-item label="港澳" prop="status">
              <el-switch v-model="service.bay" active-color="#000000" active-text="面向港澳" />
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="服务分类" prop="catalog">
              <el-select v-model="service.catalog" allow-create filterable placeholder="请选择服务分类">
                <el-option v-for="item in serviceCatalogs" :key="item.code" :value="item.code" :label="item.cnlabel"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="人才类型" prop="categories">
              <el-select v-model="service.categories" multiple placeholder="请选择人才类型">
                <el-option value="FT" label="外籍人才"></el-option>
                <el-option value="HKMT" label="港澳人才"></el-option>
                <el-option value="MT" label="大陆人才"></el-option>
                <el-option value="RT" label="海外回国人员"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="人才场景" prop="scenes">
              <el-select v-model="service.scenes" multiple placeholder="请选择人才场景">
                <el-option value="Work" label="工作"></el-option>
                <el-option value="Study" label="学习"></el-option>
                <el-option value="Invest" label="创业"></el-option>
                <el-option value="Life" label="置业"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="封面图片" prop="poster">
              <div style="color: rgba(54, 65, 85, 0.50);font-size: 14px;margin-top: -10px;margin-bottom: 5px">图片建议尺寸为360px * 360px</div>
              <el-upload
                  list-type="picture-card"
                  :action="uploader.url"
                  :accept="'image/*'"
                  :limit="1"
                  :on-preview="handlePosterPreview"
                  :on-remove="handlePosterRemove"
                  :on-success="uploadPosterSuccess"
                  :on-error="uploadPosterError"
                  :file-list="uploader.posterList"
                  :headers="uploader.headers">
                <i class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <el-dialog :visible.sync="uploader.posterVisible">
                <img :src="service.poster" alt="">
              </el-dialog>
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="横幅图片" prop="banner">
              <div style="color: rgba(54, 65, 85, 0.50);font-size: 14px;margin-top: -10px;margin-bottom: 5px">图片建议尺寸为1255px * 400px</div>
              <el-upload
                  list-type="picture-card"
                  :action="uploader.url"
                  :accept="'image/*'"
                  :limit="1"
                  :on-preview="handleBannerPreview"
                  :on-remove="handleBannerRemove"
                  :on-success="uploadBannerSuccess"
                  :on-error="uploadBannerError"
                  :file-list="uploader.bannerList"
                  :headers="uploader.headers">
                <i class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <el-dialog :visible.sync="uploader.bannerVisible">
                <img :src="service.banner" alt="">
              </el-dialog>
            </el-form-item>
          </div>
        </el-col>
      </el-row>


    </el-form>
    <!--  维护通用标签  -->
    <el-dialog title="添加通用标签" :visible.sync="tagDialogVisible" :append-to-body="true" width="30%" @click='closeDialog'>
      <el-input v-model="tag" ref="tagInput" placeholder="请输入标签关键字" @keyup.enter.native="addTag"></el-input>
      <em class="tips"><i class="el-icon-info"></i>输入回车添加标签</em>
    </el-dialog>
  </div>
</template>

<script>
import CustomEditor from "@/components/CustomEditor";
import {getService, addService, editService, editService2, getService2,addContent2} from "@/api";
import { serviceCatalogs } from "@/utils/constants";

console.log("serviceCatalogs ---- ")
console.log(serviceCatalogs)

export default {
  name: 'ServiceListItem',
  components: {CustomEditor},
  props: {
    articleId: {
      type: String
    },
    language: {
      type: String
    },
    close: {
      type: Function
    },
    reload: {
      type: Function
    }
  },
  data() {
    return {
      service: {
        id: '',
        language: 'EN',
        title: {CN: '', TC: '', EN: ''},
        poster: '',
        banner: '',
        summary: {CN: '', TC: '', EN: ''},
        meta_desc: {CN: '', TC: '', EN: ''},
        content: '',
        catalog: '',
        scenes: [],
        categories: [],
        department: '',
        tags: [],
        priority: 1,
        bay: false,
        status: '',
      },
      htmlContent: {CN: '', TC: '', EN: ''},
      rules: {
        // language: [{required: true, message: '请选择语言', trigger: 'blur'}],
        // catalog: [{required: true, message: '请选择服务类别', trigger: 'blur'}],
        // scenes: [{required: true, message: '请选择人才场景', trigger: 'blur'}],
        // categories: [{required: true, message: '请选择人才类型', trigger: 'blur'}],
        // title: [
        //   {required: true, message: '请输入文档标题', trigger: 'blur'},
        //   {min: 1, max: 256, message: '标题长度在5 到 256个字符', trigger: 'blur'},
        // ],
        // summary: [{max: 256, message: '标题长度小于1000个字符', trigger: 'blur'},
        // ],
      },
      uploader: {
        url:"/m_api/admin/resources/upload", // 图片上传请求地址,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        posterList: [],
        bannerList: [],
        posterVisible: false,
        bannerVisible: false,
      },
      lockedLanguage: false,
      isTop: false,
      isRelease: false,
      editable: true,
      contentHeight: 520,
      serviceCatalogs: serviceCatalogs,
      tag: '',
      tagDialogVisible: false,
    }
  },
  methods: {
    setTop() {
      this.service.priority = this.isTop ?  9 : 1;
    },
    setRelease() {
      this.service.status = this.isRelease ? 1 : 0;
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.articleId) {
            console.log('#, ', this.service.content);
            if (this.editable) {
              console.log('##, ', this.htmlContent);
              // this.service.content = this.htmlContent.replace(/<p><br><\/p>/g,"").replace(/<div><br><\/div>/g,"");

              console.log('###, ', this.service.content);
            }
            this.service.content = this.htmlContent;
            const params = { articleId: this.articleId };
            editService2(params, this.service).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.articleId);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          } else {
            this.service.content = this.htmlContent;

            this.service.topic = "Service";
            this.service.topic_title = "S";
            addContent2(this.service).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.articleId);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          }
          setTimeout(()=>{
            this.reload();
            this.close();
            this.editable = true;
            // this.initService();
          }, 1000);
        } else {
          console.log(this.service);
          return false;
        }
      });
    },
    onClose() {
      // console.log('$ call onCancel');
      this.editable = false;
      this.close();
    },
    getServiceData(articleId, language) {
      const params = {
        articleId,
        language
      }
      getService2(params).then(res => {
        if (res.articleId) {
          this.service = res;
          this.service.id = res.articleId;
          this.isTop = this.service.priority === 9;
          this.isRelease = this.service.status > 0;
          this.htmlContent = res.content;
          // console.log('http response: ', this.service);
          if (this.service && this.service.tags == null) this.service.tags = [];

          this.uploader.posterList = [];
          if (this.service.poster) {
            this.uploader.posterList.push({name: 'poster', url: this.service.poster});
          }
          this.uploader.bannerList = [];
          if (this.service.banner) {
            this.uploader.bannerList.push({name: 'banner', url: this.service.banner});
          }
          this.lockedLanguage = true;
        } else {
          this.$message.error('该文档数据不存在，编号：' + articleId);
        }
      }).catch(error => {
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      });
    },
    changeContentCN(value) {
      console.log('@ call changeContent' );
      this.htmlContent.CN = value;
    },
    changeContentTC(value) {
      console.log('@ call changeContent' );
      this.htmlContent.TC = value;
    },
    changeContentEN(value) {
      console.log('@ call changeContent' );
      this.htmlContent.EN = value;
    },
    initService() {
      const lang = this.service.language;
      this.service = {
        id: '',
        language: '',
        title:  {CN: '', TC: '', EN: ''},
        poster: '',
        banner: '',
        summary: {CN: '', TC: '', EN: ''},
        meta_desc: {CN: '', TC: '', EN: ''},
        content: '',
        catalog: '',
        scenes: [],
        categories: [],
        department: '',
        tags: [],
        priority: 1,
        bay: false,
        status: 0,
      };
      this.service.language = lang;
      this.htmlContent = '';
      this.uploader.posterList = [];
      this.uploader.bannerList = [];
      this.tag = '';
    },
    handlePosterPreview(file) {
      console.log('@poster@', file);
      this.uploader.posterVisible = true;
    },
    handlePosterRemove(file, fileList) {
      this.service.poster = '';
      console.log('@poster@', file, fileList);
    },
    uploadPosterSuccess(res, file) {
      console.log('@poster@: ', res);
      this.service.poster = res.path;
      console.log('@poster@: ', file);
    },
    uploadPosterError() {
      this.$message.error("封面图片上传失败");
    },
    handleBannerPreview(file) {
      console.log('@banner@', file);
      this.uploader.bannerVisible = true;
    },
    handleBannerRemove(file, fileList) {
      console.log('@banner@', file, fileList);
      this.service.banner='';
    },
    uploadBannerSuccess(res, file) {
      console.log('@banner@: ', res);
      this.service.banner = res.path;
      console.log('@banner@: ', file);
    },
    uploadBannerError() {
      this.$message.error("横幅图片上传失败");
    },
    openDialog() {
      this.tagDialogVisible = true;
      this.$nextTick(() => {this.$refs.tagInput.focus()})
    },
    closeDialog() {
      this.tagDialogVisible = false;
    },
    addTag() {
      // console.log('tags: ', this.service.tags);
      if (!this.service.tags) this.service.tags = [];
      if (this.tag) {
        this.service.tags.push(this.tag);
        this.tag = '';
      }
    },
    removeTag(tag) {
      this.service.tags.splice(this.service.tags.indexOf(tag), 1);
    },
  },
  watch: {
    articleId(value) {
      console.log(value, ' is changed.');
      if (value) {
        console.log('Edit service,', value);
        this.getServiceData(value, this.service.language);
      } else {
        this.lockedLanguage = false;
        this.initService();
      }
    },
    language(value) {
      if (value) {
        this.getServiceData(this.articleId, value);
      }
    }
  },
  mounted() {
    console.log('@', this.articleId, this.service.id);
    // console.log(this.service.id && this.service.id === '');
    if (this.articleId) {
      if (this.service.id === '') {
        this.getServiceData(this.articleId, this.language);
      }
    }

    this.$refs.quillEditor_CN.$on("input", this.changeContentCN);
    this.$refs.quillEditor_TC.$on("input", this.changeContentTC);
    this.$refs.quillEditor_EN.$on("input", this.changeContentEN);


  },
  beforeDestroy() {
    this.$off('input');
    this.initService();
  }
}
</script>

<style scoped>
.cus-card{
  border-radius: 15px;
  background: #FFF;
  padding: 20px;
  margin-bottom: 20px;
}
.avatar-uploader .el-update {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-update:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.tips {
  line-height: 16px;
}

.b-form .el-form-item__label{
  color: #364155 !important;;
  font-size: 14px !important;;
  font-style: normal !important;;
  font-weight: 600 !important;
  line-height: normal;
  padding: 0px !important;
  margin-top: 0px;
  background: red;
}
</style>
