<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>标签管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="article-search">
      <el-form-item>
        <el-input
            suffix-icon="el-icon-search"
            size="small" v-model="formInline.keyword"
            @keyup.enter.native="search"
            placeholder="请输入关键字"></el-input>
      </el-form-item>
      <el-form-item label="标签组">
        <el-select v-model="formInline.group" allow-create clearable filterable placeholder="请选择标签组">
          <el-option v-for="item in tagGroups" :key="item" :value="item" :label="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
<!--        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>-->
        <el-button size="small" type="primary" icon="el-icon-plus" class="cus-add-button" @click="handleEdit()">添加</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table
        size="small" highlight-current-row  element-loading-text="加载中……" style="width: 100%;"
        :row-class-name = "tableRowClassName"
        v-loading="loading"
        :data="listData"
    >

      <el-table-column align="center" type="selection" width="60"></el-table-column>
      <el-table-column sortable prop="id" label="编号" width="100"></el-table-column>
      <el-table-column sortable prop="group" label="标签组名称" width="200"></el-table-column>
      <el-table-column sortable prop="name" label="标签名称" width="200"></el-table-column>
      <el-table-column sortable prop="enLabel" label="英文描述" width="400"></el-table-column>
      <el-table-column sortable prop="cnLabel" label="简体中文描述" width="400"></el-table-column>
      <el-table-column sortable prop="tcLabel" label="繁体中文描述" width="400"></el-table-column>
      <el-table-column align="center" fixed="right" label="操作" min-width="200">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="onDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 编辑界面 -->
    <el-drawer :title="title" :visible.sync="drawer" size="70%">
      <TagListItem :reload="search" :close="changeDrawer" :language="formInline.language" :tagId="selectedTagId"></TagListItem>
    </el-drawer>
  </div>
</template>

<script>
import { listTags, listTagGroups, removeTag } from "@/api";
import Pagination from '@/components/Pagination'
import TagListItem from "@/views/system/tag/TagListItem";
export default {
  name: 'TagList',
  data() {
    return {
      nshow: true, //switch开启
      fshow: false, //switch关闭
      loading: false, //是显示加载
      drawer: false,
      selectedTagId: null,
      title: '',
      formInline: {
        page: 0,
        size: 10,
        keyword: '',
        group: '',
        language: 'EN'
      },
      tagGroups: [],
      userparm: [], //搜索权限
      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 0,
        pageSize: 10,
        total: 10
      }
    }
  },
  // 注册组件
  components: {
    TagListItem,
    Pagination
  },
  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.getTagGroups();
    this.getdata(this.formInline);
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    getdata(parameter) {
      this.loading = true

      listTags(parameter).then(res => {
        this.loading = false;
        if (res.numberOfElements > 0) {
          console.log('Get services: ', res.totalElements, res.totalPages, res.number, res.size);
          this.listData = res.data;
          this.pageparm.currentPage = res.number + 1;
          this.pageparm.pageSize = res.size;
          this.pageparm.total = res.totalElements;
        } else {
          console.log('@ no data.')
        }
      }).catch(error => {
        this.loading = false;
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      })

    },
    getTagGroups() {
      listTagGroups({}).then(res => {
        this.tagGroups = res;
      }).catch(error => {
        console.log('Tag Groups init error.', error);
      });
    },
    tableRowClassName({row, rowIndex}) {
      if (row.status === 0) {
        return 'warning-row';
      }
      if (row.priority === 9) {
        return 'top-row';
      }
      return '';
    },

    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage - 1
      this.formInline.size = parm.pageSize
      this.getdata(this.formInline)
    },
    // 搜索事件
    search() {
      this.getdata(this.formInline)
    },
    //显示编辑界面
    handleEdit: function(index, row) {
      if (row !== undefined && row !== 'undefined') {
        this.title = '修改标签信息'
        this.selectedTagId = row.id;
      } else {
        this.title = '添加标签信息'
        this.selectedTagId = null;
      }
      this.drawer = true;
    },
    changeDrawer() {
      this.drawer = !this.drawer;
    },

    onDelete(index, row) {
      this.$confirm('该操作将同时删除文档对应的标签，确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('call remove benefit api: ', index, row);
        const params = {tagId: row.id};
        removeTag(params).then(res => {
          setTimeout(() => {
            this.$message({
              type: 'success',
              message: '该标签已删除!'
            });
            this.getdata(this.formInline);
          }, 1000);
        }).catch(err => {
          this.loading = false
          console.log('Error: ', err);
          this.$message.error('该信息删除失败，请稍后再试！')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

  }
}
</script>

<style scoped>
.article-search {
  margin-top: 20px;
}

.el-table .warning-row {
  background-color: oldlace;
}

.el-table .top-row {
  background-color: #f0f9EB;
}
</style>
