import {download, httpDelete, httpGet, loginRequest, req, request} from '../utils/http';
import axios from "axios";

/**
 * 登录请求
 *
 * @param params 参数
 * @returns {Promise<AxiosResponse<any>>}
 */
export const login = (params) => {return loginRequest("post", "/api/auth/login", params) };


/**
 * 获取所有的福利政策列表
 *
 * @param params 参数
 * @returns {Promise<*>}
 */
export const listBenefits = (params) => { return httpGet("/api/benefits/all", params)};
export const listBenefits2 = (params) => { return httpGet("/m_api/admin/benefits/all", params)};

/**
 * 获取一条福利政策
 *
 * @param params
 * @returns {Promise<*>}
 */
export const getBenefit = (params) => { return httpGet("/api/benefits/view", params)};

export const getBenefit2 = (params) => { return httpGet("/m_api/admin/content/view", params)};

/**
 * 添加一条福利政策
 *
 * @param params
 * @returns {Promise<*>}
 */
export const addBenefit = (data) => { return request("post", "/api/content/add",null, data)};
export const addContent2 = (data) => { return request("post", "/m_api/admin/content/add",null, data)};

/**
 * 修改一条福利政策
 *
 * @param params
 * @returns {Promise<*>}
 */
export const editBenefit = (params, data) => { return request("put", "/api/benefits/edit", params, data)};
export const editBenefit2 = (params, data) => { return request("put", "/m_api/admin/content/edit", params, data)};

/**
 * 删除一条福利政策（同时删除其他语言版本）
 * @param params
 * @returns {Promise<*>}
 */
export const removeBenefit = (params) => { return httpDelete("/api/benefits/delete", params)};
export const removeContent2 = (params) => { return httpDelete("/m_api/admin/content/deleted", params)};

export const listEvents = (params) => { return httpGet("/api/events/all", params)};
export const listEvents2 = (params) => { return httpGet("/m_api/admin/events/all", params)};
export const getEvent = (params) => { return httpGet("/api/events/view", params)};
export const getEvent2 = (params) => { return httpGet("/m_api/admin/content/view", params)};
export const addEvent = (data) => { return request("post", "/api/events/add",null, data)};
export const editEvent2 = (params, data) => { return request("put", "/m_api/admin/content/edit", params, data)};
export const editEvent = (params, data) => { return request("put", "/api/events/edit", params, data)};
export const removeEvent = (params) => { return httpDelete("/api/events/delete", params)};

export const listServices = (params) => { return httpGet("/api/services/all", params)};
export const listServices2 = (params) => { return httpGet("/m_api/admin/services/all", params)};
export const getService = (params) => { return httpGet("/api/services/view", params)};
export const getService2 = (params) => { return httpGet("/m_api/admin/content/view", params)};
export const addService = (data) => { return request("post", "/api/services/add",null, data)};
export const editService = (params, data) => { return request("put", "/api/services/edit", params, data)};
export const editService2 = (params, data) => { return request("post", "/m_api/admin/content/edit", params, data)};
export const removeService = (params) => { return httpDelete("/api/services/delete", params)};

export const listLives = (params) => { return httpGet("/api/lives/all", params)};
export const listLives2 = (params) => { return httpGet("/m_api/admin/lives/all", params)};
export const getLife = (params) => { return httpGet("/api/lives/view", params)};
export const getLife2 = (params) => { return httpGet("/m_api/admin/content/view", params)};
export const addLife = (data) => { return request("post", "/api/lives/add",null, data)};
export const editLife = (params, data) => { return request("put", "/api/lives/edit", params, data)};
export const editLife2 = (params, data) => { return request("put", "/m_api/admin/content/edit", params, data)};
export const removeLife = (params) => { return httpDelete("/api/lives/delete", params)};

export const listBays = (params) => { return httpGet("/api/bays/all", params)};
export const listBays2 = (params) => { return httpGet("/m_api/admin/bay/all", params)};
export const getBay = (params) => { return httpGet("/api/bays/view", params)};
export const getBay2 = (params) => { return httpGet("/m_api/admin/content/view", params)};
export const addBay = (data) => { return request("post", "/api/bays/add",null, data)};
export const editBay = (params, data) => { return request("put", "/api/bays/edit", params, data)};
export const editBay2 = (params, data) => { return request("put", "/m_api/admin/content/edit", params, data)};
export const removeBay = (params) => { return httpDelete("/api/bays/delete", params)};

export const listQuestions = (params) => { return httpGet("/api/questions/all", params)};
export const getQuestion = (params) => { return httpGet("/api/questions/view", params)};
export const addQuestion = (data) => { return request("post", "/api/questions/add",null, data)};
export const editQuestion = (params, data) => { return request("put", "/api/questions/edit", params, data)};
export const removeQuestion = (params) => { return httpDelete("/api/questions/delete", params)};

export const getQuestionOptions = (params) => { return httpGet("/api/questions/options", params)};
export const addQuestionOption = (data) => { return request("post","/api/questions/options/add", null, data)};
export const editQuestionOption = (params, data) => { return request("put","/api/questions/options/edit", params, data)};
export const delQuestionOption = (params) => { return httpDelete("/api/questions/options/delete", params)};

export const listPositions = (data) => { return request("post","/api/positions/all", null, data)};
export const listPositions2 = (data) => { return request("post","/m_api/admin/positions/all", null, data)};
export const getPosition = (params) => { return httpGet("/api/positions/view", params)};
export const getPosition2 = (params) => { return httpGet("/m_api//admin/positions/view", params)};
export const addPosition = (data) => { return request("post", "/api/positions/add",null, data)};
export const addPosition2 = (data) => { return request("post", "/m_api/admin/positions/add",null, data)};
export const editPosition = (params, data) => { return request("put", "/api/positions/edit", params, data)};
export const editPosition2 = (params, data) => { return request("put", "/m_api/admin/positions/edit", params, data)};
export const removePosition = (params) => { return httpDelete("/api/positions/delete", params)};
export const removePosition2 = (params) => { return httpDelete("/m_api/admin/positions/deleted", params)};
export const downloadResume = (params) => { return download('/api/resources/batch-download-resumes', params)};

export const getCompanyList = (params) => { return httpGet("/api/companies/list", params)};
export const getCompanyList2 = (params) => { return httpGet("/m_api/admin/companies/list", params)};
export const listCompanies = (params) => { return httpGet("/api/companies/all", params)};
export const listCompanies2 = (params) => { return httpGet("/m_api/admin/companies/all", params)};
export const getCompany = (params) => { return httpGet("/api/companies/view", params)};
export const getCompany2 = (params) => { return httpGet("/m_api/admin/companies/view", params)};
export const addCompany = (data) => { return request("post", "/api/companies/add",null, data)};
export const addCompany2 = (data) => { return request("post", "/m_api/admin/companies/add",null, data)};
export const editCompany = (params, data) => { return request("put", "/api/companies/edit", params, data)};
export const editCompany2 = (params, data) => { return request("put", "/m_api/admin/companies/edit", params, data)};
export const removeCompany = (params) => { return httpDelete("/api/companies/delete", params)};
export const removeCompany2 = (params) => { return httpDelete("/m_api/admin/companies/deleted", params)};

export const listUsers = (params) => { return httpGet("/api/users/all", params)};
export const addUser = (data) => { return request('POST', "/api/users/add", null, data)};
export const editUser = (params, data) => {return request('PUT', "/api/users/edit", params, data)};
export const removeUser = (params) => {return httpDelete("/api/users/delete", params)};
export const changePasswd = (data) => {return request('PUT', "/api/users/change-passwd",null, data)};
export const resetPasswd = (params) => { return httpGet("/api/users/reset-passwd", params)};


export const listTags = (params) => { return httpGet("/api/tags/all", params)};
export const listTagGroups = (params) => { return httpGet("/api/tags/group", params)};
export const getTag = (params) => { return httpGet("/api/tags/view", params)};
export const addTag = (data) => { return request("post", "/api/tags/add",null, data)};
export const editTag = (params, data) => { return request("put", "/api/tags/edit", params, data)};
export const removeTag = (params) => { return httpDelete("/api/tags/delete", params)};



export const listPromotions = (params) => { return httpGet("/m_api/admin/promotions/all", params)};
export const getPromotion = (params) => { return httpGet("/m_api/admin/promotions/view", params)};
export const editPromotion = (params, data) => { return request("post", "/m_api/admin/promotions/edit", params, data)};
export const addPromotion = (data) => { return request("post", "/m_api/admin/promotions/add",null, data)};
export const removePromotion = (params) => { return httpDelete("/m_api/admin/promotions/deleted", params)};


export const listCatalog = (params) => { return httpGet("/m_api/admin/catalog/all", params)};
export const getCatalog = (params) => { return httpGet("/m_api/admin/catalog/view", params)};
export const editCatalog = (params, data) => { return request("post", "/m_api/admin/catalog/edit", params, data)};
export const addCatalog = (data) => { return request("post", "/m_api/admin/catalog/add",null, data)};
export const removeCatalog = (params) => { return httpDelete("/m_api/admin/catalog/deleted", params)};


export const listPartner = (params) => { return httpGet("/m_api/admin/partner/all", params)};
export const getPartner = (params) => { return httpGet("/m_api/admin/partner/view", params)};
export const editPartner = (params, data) => { return request("post", "/m_api/admin/partner/edit", params, data)};
export const addPartner = (data) => { return request("post", "/m_api/admin/partner/add",null, data)};
export const removePartner = (params) => { return httpDelete("/m_api/admin/partner/deleted", params)};




export const listRepresent = (params) => { return httpGet("/m_api/admin/represent/all", params)};
export const getRepresent = (params) => { return httpGet("/m_api/admin/represent/view", params)};
export const editRepresent = (params, data) => { return request("post", "/m_api/admin/represent/edit", params, data)};
export const addRepresent = (data) => { return request("post", "/m_api/admin/represent/add",null, data)};
export const removeRepresent = (params) => { return httpDelete("/m_api/admin/represent/deleted", params)};

//简历
export const listResumes = (params) => { return httpGet("/m_api/admin/resume/all", params)};

export const listTagOptions = (params) => { return httpGet("/api/tags/tag-options", params)};
export const listArticleTags = (params) => { return httpGet("/api/articles/tags", params)};
export const removeArticleTag = (params) => {return httpDelete("/api/article/tags/delete", params)};
export const addArticleTag = (data) => {return request("post", "/api/articles/tags/add", null, data)};
