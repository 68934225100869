<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search">
      <el-form-item>
        <el-input
            suffix-icon="el-icon-search"
            size="small" v-model="formInline.keyword"
            @keyup.enter.native="search"
            placeholder="请输入关键字"></el-input>
      </el-form-item>
      <el-form-item>
<!--        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>-->
        <el-button size="small" type="primary" icon="el-icon-plus" class="cus-add-button" @click="handleAdd()">添加用户</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table size="small" @selection-change="selectChange" :data="userList" highlight-current-row v-loading="loading"
               element-loading-text="拼命加载中" style="width: 100%;">
      <el-table-column align="center" type="selection" width="50">
      </el-table-column>
      <el-table-column align="center" sortable prop="username" label="用户名" width="200">
      </el-table-column>
      <el-table-column align="center" sortable prop="firstName" label="真实姓名" width="160">
      </el-table-column>
      <el-table-column align="center" sortable prop="email" label="邮箱" width="200">
      </el-table-column>
      <el-table-column align="center" prop="role" label="角色" width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.role === 'ADMIN'" type="primary" effect="dark">管理员</el-tag>
          <el-tag v-else-if="scope.row.role === 'TENANT'" type="success" effect="dark">租 户</el-tag>
          <el-tag v-else type="warning" effect="dark">用 户</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" sortable prop="education" label="学历" width="120">
      </el-table-column>
      <el-table-column align="center" sortable prop="country" label="国家或地区" min-width="200">
      </el-table-column>
      <el-table-column align="center" sortable prop="age" label="年龄" min-width="100">
      </el-table-column>
      <el-table-column align="center" sortable prop="lastPasswordResetDate" label="激活时间" min-width="160">
        <template slot-scope="scope">
          <div>{{ scope.row.lastPasswordResetDate|timestampToTime }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" sortable prop="isLock" label="状态" min-width="80">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="success">已激活</el-tag>
          <el-tag v-else type="danger">未激活</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" min-width="250">
        <template slot-scope="scope">
          <el-button size="mini" v-show="scope.row.id !== sys_admin_id" @click="handleEdit(scope.$index, scope.row)">
            编辑
          </el-button>
          <el-button size="mini" v-show="scope.row.id !== sys_admin_id" type="danger"
                     @click="deleteUser(scope.$index, scope.row)">删除
          </el-button>
          <el-button size="mini" v-show="scope.row.id !== sys_admin_id" type="success"
                     @click="resetPassword(scope.$index, scope.row)">重置密码
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 新增用户界面 -->
    <el-dialog :title="title" :visible.sync="addFormVisible" width="30%" @click='closeDialog("edit")'>
      <el-form label-width="120px" ref="addForm" :model="userEntity" :rules="rules" label-position="top">
        <el-form-item label="用户名" prop="username">
          <el-input size="small" v-model="userEntity.username" auto-complete="off" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="firstName">
          <el-input size="small" v-model="userEntity.firstName" auto-complete="off" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input size="small" v-model="userEntity.password" auto-complete="off" placeholder="请设置登录密码"
                    show-password></el-input>
        </el-form-item>
        <el-form-item label="再次输入" prop="password2">
          <el-input size="small" v-model="userEntity.password2" auto-complete="off" placeholder="请再次输入登录密码"
                    show-password></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role">
          <el-select size="small" v-model="userEntity.role" placeholder="请选择" class="userRole">
            <el-option label="管理员" value="ADMIN"></el-option>
            <el-option label="租户" value="TENANT"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮件" prop="email">
          <el-input size="small" v-model="userEntity.email" placeholder="请输入邮箱地址"></el-input>
        </el-form-item>
        <el-form-item label="企业信用编码" prop="uscc">
          <el-input v-if="userEntity.role === 'TENANT'" v-model="userEntity.uscc"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click='closeDialog("add")'>取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title" @click="submitForm('addForm')">保存
        </el-button>
      </div>
    </el-dialog>
    <!-- 修改用户界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click='closeDialog("edit")'>
      <el-form label-width="120px" ref="editForm" :model="userEntity" :rules="rules">
        <el-form-item label="用户名" prop="username">
          <el-input size="small" :value="userEntity.username" readonly auto-complete="off" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="firstName">
          <el-input size="small" v-model="userEntity.firstName" auto-complete="off" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role">
            <el-tag v-if="userEntity.role === 'ADMIN'" type="primary" effect="dark">管理员</el-tag>
            <el-tag v-else-if="userEntity.role === 'TENANT'" type="success" effect="dark">租 户</el-tag>
            <el-tag v-else type="warning" effect="dark">用 户</el-tag>
        </el-form-item>
        <el-form-item label="邮件" prop="email">
          <el-input size="small" v-model="userEntity.email" placeholder="请输入邮箱地址"></el-input>
        </el-form-item>
        <el-form-item label="企业信用编码" prop="uscc">
          <el-input v-if="userEntity.role === 'TENANT'" v-model="userEntity.uscc"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click='closeDialog("edit")'>取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title" @click="submitForm('editForm')">保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 导入请求方法
import {addUser, editUser, listUsers, removeUser, resetPasswd} from '../../api/index'
import Pagination from '../../components/Pagination'

export default {
  name: 'UserList',
  data() {
    var validPasswd = (rule, value, callback) => {
      if (this.skipValidatePass) callback();
      if (value === '') {
        callback(new Error('请再次输入登录密码'));
      } else if (value !== this.userEntity.password) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    };
    return {
      loading: false, //是显示加载
      title: '添加用户',
      sys_admin_id: '13814000-1dd2-11b2-8080-808080808080',
      addFormVisible: false,
      editFormVisible: false, //控制编辑页面显示与隐藏
      // 选择数据
      selectdata: [],
      userEntity: {
        id: '',
        username: '',
        firstName: '',
        email: '',
        password: '',
        password2: '',
        role: '',
        uscc: '',
      },
      skipValidatePass: false,
      // rules表单验证
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        firstName: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        role: [{required: true, message: '请选择角色', trigger: 'blur'}],
        password: [
          {required: true, message: '请输入登录密码', trigger: 'blur'},
          {min: 8, message: '输入密码长度必须大于6位'},
          {pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,30}$/, message: '必须包含字母大写、小写、数字、特殊字符中的三种'},
          {
            validator: (rule, value, callback) => {
              if (value === this.userEntity.username) callback(new Error('密码不能与用户名相同'))
              else callback()
            },
          },
        ],
        password2: [{required: true, validator: validPasswd, trigger: 'blur'}],
        email: [
          {required: true, message: '请输入邮箱', trigger: 'blur'},
          {
            pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            required: true,
            message: '请输入正确的邮箱',
            trigger: 'blur'
          }
        ],
      },
      // 请求数据参数
      formInline: {
        page: 0,
        size: 10,
        keyword: '',
      },
      //用户数据
      userList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      // 分页参数
      pageparm: {
        currentPage: 0,
        pageSize: 10,
        total: 10
      }
    }
  },
  // 注册组件
  components: {
    Pagination
  },

  /**
   * 数据发生改变
   */
  watch: {},

  /**
   * 创建完毕
   */
  created() {
    this.getdata(this.formInline)
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取数据方法
    getdata(parameter) {
      this.loading = true

      /***
       * 调用接口，注释上面模拟数据 取消下面注释
       */
      listUsers(parameter).then(res => {
        this.loading = false
        if (res) {
          this.userList = res.content;
          // 分页赋值
          this.pageparm.currentPage = res.number + 1;
          this.pageparm.pageSize = res.size;
          this.pageparm.total = res.totalElements;
        } else {
          this.$message.warning("为获取到数据")
        }
      }).catch(err => {
        console.log(err);
        this.$message.error("获取用户数据失败!");
        this.loading = false
      })
    },
    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage - 1;
      this.formInline.limit = parm.pageSize;
      this.getdata(this.formInline);
    },
    //搜索事件
    search() {
      this.getdata(this.formInline)
    },
    //显示编辑界面
    handleAdd: function () {
      this.addFormVisible = true
      this.initUser();
      this.title = '添加用户'
    },
    handleEdit: function (index, row) {
      this.editFormVisible = true
      this.title = '修改用户'
      if (row !== undefined && row !== 'undefined') {
        this.userEntity.id = row.id
        this.userEntity.username = row.username
        this.userEntity.firstName = row.firstName
        this.userEntity.role = row.role
        this.userEntity.uscc = row.uscc
        this.userEntity.email = row.email
      } else {
        this.$message.warning('为获取到用户信息!')
      }
    },
    // 编辑、添加提交方法
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 请求方法
          if (this.userEntity.id === '') {
            addUser(this.userEntity).then(res => {
              this.addFormVisible = false
              this.loading = false
              if (res) {
                this.getdata(this.formInline)
                this.$message.success('数据保存成功！');
                console.log('新增用户成功，id: ', res.id);
                this.initUser();
              }
            }).catch(err => {
              console.log(err)
              this.editFormVisible = false
              this.loading = false
              this.$message.error('保存失败，请稍后再试！')
            })
          } else {
            const params = { 'id': this.userEntity.id };
            console.log('params: ', params);
            editUser(params, this.userEntity).then(res => {
              this.editFormVisible = false;
              this.loading = false;
              if (res) {
                this.$message.success("数据保存成功");
                console.log('用户修改成功，id: ', res.id);
                this.initUser();
              }
            }).catch(err => {
              console.log(err)
              this.editFormVisible = false
              this.loading = false
              this.$message.error('保存失败，请稍后再试！')
            })
          }
        } else {
          return false
        }
        this.skipValidatePass = false;
      })
    },
    initUser() {
      this.userEntity = {
        id: '',
        username: '',
        firstName: '',
        email: '',
        password: '',
        password2: '',
        role: '',
        uscc: '',
      };
    },
    handleClick(data, checked, node) {
      if (checked) {
        this.$refs.tree.setCheckedNodes([])
        this.$refs.tree.setCheckedNodes([data])
        this.unitparm.deptId = data.id
        this.unitparm.deptName = data.name
        //交叉点击节点
      }
    },
    // 选择复选框事件
    selectChange(val) {
      this.selectdata = val
    },
    // 关闭编辑、增加弹出框
    closeDialog(dialog) {
      if (dialog === 'edit') {
        this.editFormVisible = false

      } else if (dialog === 'add') {
        this.addFormVisible = false
      } else if (dialog === 'unit') {
        this.unitAccessshow = false
      }
    },
    // 删除用户
    deleteUser(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除
        removeUser({id: row.id})
            .then(res => {
              if (res) {
                this.$message.success('用户已删除');
                this.getdata(this.formInline)
              } else {
                this.$message.warning('用户删除失败：', res.message);
              }
            }).catch(err => {
              console.log(err);
              this.loading = false
              this.$message.error('数据删除失败，请稍后再试！')
            })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除！'
        })
      })
    },
    // 重置密码
    resetPassword(index, row) {
      const params = {
        userId: row.id,
      };

      this.$confirm('确定要重置密码吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        resetPasswd(params).then(res => {
          if (res) {
            this.$message.success('密码重置成功！');
            this.getdata(this.formInline)
          } else {
            this.$message.warning('密码重置失败!')
          }
        })
            .catch(err => {
              this.loading = false
              console.log(err);
              this.$message.error('密码重置失败, 请联系管理员!');
            })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消重置密码！'
        })
      })
    },

  },
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}
</style>

