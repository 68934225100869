import { render, staticRenderFns } from "./CompanyListItem.vue?vue&type=template&id=ff98f022&scoped=true&xmlns%3Ael-col=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml"
import script from "./CompanyListItem.vue?vue&type=script&lang=js"
export * from "./CompanyListItem.vue?vue&type=script&lang=js"
import style0 from "./CompanyListItem.vue?vue&type=style&index=0&id=ff98f022&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff98f022",
  null
  
)

export default component.exports