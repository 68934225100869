<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>职位管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form ref="searchForm" :inline="true" :model="formInline" class="search">
      <el-form-item>
        <el-input
            suffix-icon="el-icon-search"
            size="small" v-model="formInline.keyword"
            @keyup.enter.native="search"
            placeholder="请输入关键字"></el-input>
      </el-form-item>
<!--      <el-form-item>-->
<!--        <el-select v-model="formInline.language" placeholder="请选择语言">-->
<!--          <el-option value="EN">English</el-option>-->
<!--          <el-option value="CN">简体中文</el-option>-->
<!--          <el-option value="TC">繁體中文</el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <el-form-item>
<!--        <el-button size="small" type="primary" icon="el-icon-search" @click="search('searchForm')">搜索</el-button>-->
<!--        <el-button size="small" type="primary" icon="el-icon-plus" @click="handleImport()">导入</el-button>-->
        <el-button size="small" type="success"  class="cus-add-button" icon="el-icon-plus" @click="handleEdit()">添加新的工作岗位信息</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table
        size="small" highlight-current-row  element-loading-text="加载中……" style="width: 100%;"
        :row-class-name = "tableRowClassName"
        v-loading="loading"
        :data="listData"
    >
      <el-table-column align="center" type="selection" width="60"></el-table-column>
      <el-table-column  prop="name" label="岗位名称" width="250"></el-table-column>
      <el-table-column  prop="company_name" label="招聘单位" width="250"></el-table-column>
      <el-table-column  prop="category" label="职位类别" width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.category != null"><span class="cals-type-tag">{{ scope.row.category }}</span></div>
        </template>
      </el-table-column>
      <el-table-column  prop="classify" label="职位类型" width="120">
        <template slot-scope="scope">
          <div><span class="pos-type-tag">{{ scope.row.classify }}</span></div>
        </template>
      </el-table-column>
      <el-table-column  prop="employ" label="招聘人数" width="100">
        <template slot-scope="scope">
          <div style="text-align: center"><span class="cals-type-tag">{{ scope.row.employ }}</span></div>
        </template>
      </el-table-column>
      <el-table-column  prop="salaryFrom" label="薪资范围" width="140">
        <template slot-scope="scope">
          <div style="text-align: center">
            <span class="cals-type-tag">{{ scope.row.salary_from }} ~ {{ scope.row.salary_to }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column  prop="workplace" label="工作地点" width="200"></el-table-column>
      <el-table-column  prop="province" label="省" width="100"></el-table-column>
      <el-table-column  prop="city" label="市" width="100"></el-table-column>
      <el-table-column align="center" fixed="right" label="操作" min-width="120">
        <template slot-scope="scope">
          <div><el-button size="mini" type="warning" class="cus-edit-button" @click="handleEdit(scope.$index, scope.row)">编辑</el-button></div>
<!--          <div><el-button size="mini" v-show="scope.row.delivery > 0" type="primary" @click="handleDownload(scope.$index, scope.row)" icon="el-icon-download">下载</el-button></div>-->
          <div style="margin-top: 5px"><el-button size="mini" type="danger" class="cus-deleted-button" @click="deletePosition(scope.$index, scope.row)">删除</el-button></div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 导入界面 -->
    <el-dialog title="上传职位信息" :visible.sync="importDialogVisible" size="30%">
      <el-upload
          class="upload-position"
          ref="upload"
          action="/api/positions/upload-position"
          drag
          :on-success="handleImportSuccess"
          :on-error="handleImportError"
          :data="uploader"
          :headers="headers"
          :auto-upload="false"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">Tips： 导入职位数据，要求Excel格式文件，大小不超过5M。 <a href="../../assets/template/position_template.xlsx">模板点击下载</a> </div>
      </el-upload>
      <div class="dialog-button">
        <div><el-checkbox v-model="uploader.release">直接发布</el-checkbox></div>
        <div><el-button @click="handleClose">取消</el-button> &nbsp; <el-button type="primary" @click="handleSubmit">确定</el-button></div>
      </div>
    </el-dialog>
    <!-- 编辑界面 -->
    <el-drawer :title="title" :visible.sync="drawer" size="70%">
      <PositionListItem :reload="search" :close="changeDrawer" :language="formInline.language" :positionId="selectedPositionId"></PositionListItem>
    </el-drawer>
  </div>
</template>

<script>
import {downloadResume, listPositions, listPositions2, removePosition, removePosition2} from "@/api";
import Pagination from '@/components/Pagination'
import PositionListItem from "@/views/recruit/PositionListItem";
export default {
  name: 'PositionList',
  data() {
    return {
      loading: false, //是显示加载
      drawer: false,
      importDialogVisible: false,
      selectedPositionId: null,
      title: '',
      formInline: {
        page: 0,
        size: 10,
        keyword: '',
        category: '',
        language: null
      },
      uploader: {
        release: false
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      userparm: [], //搜索权限
      listData: [], //用户数据
      fileList: [],
      // 分页参数
      pageparm: {
        currentPage: 0,
        pageSize: 10,
        total: 10
      }
    }
  },
  // 注册组件
  components: {
    PositionListItem,
    Pagination
  },
  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.getdata(this.formInline)
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    getdata(parameter) {
      this.loading = true

      listPositions2(parameter).then(res => {
        this.loading = false;
        if (res.numberOfElements > 0) {
          console.log('Get: ', res.totalElements, res.totalPages, res.number, res.size);
          this.listData = res.data;
          this.pageparm.currentPage = res.number + 1;
          this.pageparm.pageSize = res.size;
          this.pageparm.total = res.totalElements;
        } else {
          console.log('@ no life data.')
        }
      }).catch(error => {
        this.loading = false;
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      })

    },
    tableRowClassName({row}) {
      // console.log('@', rowIndex, row.status, row.priority);
    //   if (row.always) {
    //     return 'top-row';
    //   }
    //   if (row.hotspot) {
    //     return 'hot-row';
    //   }
    //   return '';
    },

    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage - 1
      this.formInline.size = parm.pageSize
      this.getdata(this.formInline)
    },
    // 搜索事件
    search() {
      this.getdata(this.formInline)
    },
    handleImport: function () {
      this.importDialogVisible = true;
    },
    handleImportSuccess: function (res) {
      if (res) {
        this.$message.success('职位信息成功导入' + res.length + '条');
        this.importDialogVisible = false;
        this.fileList.slice(-1);
      }
    },
    handleImportError: function (err) {
      if (err) {
        const error = JSON.parse(err.message);
        console.log('Error: ', error);

        this.$message.error('职位信息导入失败: ' + error.message);
      }
    },
    handleClose() {
      this.importDialogVisible = false;
    },
    handleSubmit() {
      this.$refs.upload.submit();
    },
    //显示编辑界面
    handleEdit: function(index, row) {
      // console.log('@', index, row);
      if (row !== undefined && row !== 'undefined') {
        this.title = '修改职位信息'
        this.selectedPositionId = row.id;
      } else {
        this.title = '添加职位信息'
        this.selectedPositionId = null;
      }
      this.drawer = true;
    },
    changeDrawer() {
      // console.log('@ call changeDrawer()');
      this.drawer = !this.drawer;
    },
    handleDownload: function (index, row) {
      if (row !== undefined && row !== 'undefined') {
        if (row.delivery > 0) {
          const params = {positionId: row.id};
          downloadResume(params).then(res => {
            this.download(res);
          });
        }
      }
    },
    download(res) {
      //type是文件类，详情可以参阅blob文件类型
      let blob = new Blob([res.data], { type: "application/zip" });
      // 创建新的URL并指向File对象或者Blob对象的地址
      const blobURL = window.URL.createObjectURL(blob)
      // 创建a标签，用于跳转至下载链接
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobURL
      tempLink.setAttribute('download', decodeURI(res.headers['content-disposition'].split(';')[1].split('=')[1]))
      // 兼容：某些浏览器不支持HTML5的download属性
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
      }
      // 挂载a标签
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      // 释放blob URL地址
      window.URL.revokeObjectURL(blobURL);
    },
    // 删除一项职位信息
    deletePosition(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('call remove position api: ', index, row);
        const params = {id: row.id};
        removePosition2(params).then(res => {
          setTimeout(() => {
            this.$message.success('该职位信息已删除!');
            this.getdata(this.formInline);
          }, 1000);
        }).catch(err => {
          this.loading = false
          console.log('Error: ', err);
          this.$message.error('该信息删除失败，请稍后再试！')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
  }
}
</script>

<style scoped>
@import "@/assets/css/global.css";

.search {
  margin-top: 20px;
}
.dialog-button {
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap: 2rem;
  height: 200px;
}
.dialog-button div {
  align-items: center;
  justify-content: center;
  height: 50px;
}

.pos-type-tag{
  border-radius: 6px;
  padding: 2px 10px;
  background: rgba(245, 148, 4, 0.20);
  color: #F59404;
}

.cals-type-tag{
  border-radius: 6px;
  background: rgba(54, 65, 85, 0.20);
  color: #364155;
  padding: 2px 10px;
}
</style>
