/**
* 左边菜单
*/
<template>
  <el-menu :collapse="collapsed" collapse-transition router :default-active="$route.path" unique-opened class="el-menu-vertical-demo" background-color="#334157" text-color="#fff" active-text-color="#ffd04b">
    <div class="logobox">
      <img src="../assets/admin-logo.svg" style="height: 100%" alt="">
    </div>
    <el-submenu v-for="menu in allmenu" :key="menu.menuid" :index="menu.menuname">
      <template slot="title">
        <i :class="menu.icon"></i>
        <span>{{menu.menuname}}</span>
      </template>
      <el-menu-item-group>
        <el-menu-item v-for="chmenu in menu.menus" :index="'/'+chmenu.url" :key="chmenu.menuid">
          <i :class="chmenu.icon"></i>
          <span>{{chmenu.menuname}}</span>
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>
<script>

export default {
  name: 'LeftNavigation',
  data() {
    return {
      collapsed: false,
      allmenu: []
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    // 获取图形验证码
    let res = {
      success: true,
      data: [
        {
          menuid: 1,
          icon: 'iconfont li-icon-xitongguanli',
          menuname: ' 内容管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 11,
              icon: 'iconfont li-icon-xitongguanli',
              menuname: '福利政策',
              hasThird: 'N',
              url: 'benefit/benefits',
              menus: null
            },
            {
              menuid: 12,
              icon: 'iconfont li-icon-xitongguanli',
              menuname: '活动信息',
              hasThird: 'N',
              url: 'event/events',
              menus: null
            },
            {
              menuid: 13,
              icon: 'iconfont li-icon-xitongguanli',
              menuname: '服务信息',
              hasThird: 'N',
              url: 'service/services',
              menus: null
            },
            // {
            //   menuid: 131,
            //   icon: 'iconfont li-icon-xitongguanli',
            //   menuname: '服务分类',
            //   hasThird: 'N',
            //   url: 'service/services_cat',
            //   menus: null
            // },
            {
              menuid: 14,
              icon: 'iconfont li-icon-xitongguanli',
              menuname: '生活信息',
              hasThird: 'N',
              url: 'life/lives',
              menus: null
            },
            {
              menuid: 15,
              icon: 'iconfont li-icon-xitongguanli',
              menuname: '港澳专区',
              hasThird: 'N',
              url: 'special-area/bays',
              menus: null
            },
            {
              menuid: 16,
              icon: 'iconfont li-icon-xitongguanli',
              menuname: '招商板块',
              hasThird: 'N',
              url: 'promotions/list',
              menus: null
            },
            {
              menuid: 17,
              icon: 'iconfont li-icon-xitongguanli',
              menuname: '国际人才合伙人',
              hasThird: 'N',
              url: 'partner/list',
              menus: null
            },
            {
              menuid: 18,
              icon: 'iconfont li-icon-xitongguanli',
              menuname: '部分入驻代表 ',
              hasThird: 'N',
              url: 'represent/list',
              menus: null
            },
          ]
        },
        {
          menuid: 2,
          icon: 'el-icon-document-copy',
          menuname: ' 招聘管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 21,
              icon: 'el-icon-office-building',
              menuname: '企业信息管理',
              hasThird: 'N',
              url: 'recruit/companies',
              menus: null
            },
            {
              menuid: 22,
              icon: 'el-icon-suitcase',
              menuname: '职位信息管理',
              hasThird: 'N',
              url: 'recruit/positions',
              menus: null
            },
            {
              menuid: 23,
              icon: 'el-icon-suitcase',
              menuname: '简历投递管理',
              hasThird: 'N',
              url: 'recruit/resume',
              menus: null
            },
          ]
        },
        {
          menuid: 3,
          icon: 'el-icon-document-remove',
          menuname: '问卷管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 31,
              icon: 'el-icon-document',
              menuname: '人才报告',
              hasThird: 'N',
              url: 'questionnaire/talentReport',
              menus: null
            },
            // {
            //   menuid: 32,
            //   icon: 'iconfont li-icon-shangchengxitongtubiaozitihuayuanwenjian91',
            //   menuname: '  成本计算',
            //   hasThird: 'N',
            //   url: 'questionnaire/costCalculator',
            //   menus: null
            // }
          ]
        },
        {
          menuid: 71,
          icon: 'el-icon-setting',
          menuname: ' 系统管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 72,
              icon: 'el-icon-user-solid',
              menuname: '用户管理',
              hasThird: 'N',
              url: 'system/user',
              menus: null
            },
            {
              menuid: 73,
              icon: 'el-icon-collection',
              menuname: '标签管理',
              hasThird: 'N',
              url: 'system/tags',
              menus: null
            },

          ]
        }
      ],
      msg: 'success'
    }
          this.allmenu = res.data

    // menu(localStorage.getItem('logintoken'))
    //   .then(res => {
    //     console.log(JSON.stringify(res))
    //     if (res.success) {
    //       this.allmenu = res.data
    //     } else {
    //       this.$message.error(res.msg)
    //       return false
    //     }
    //   })
    //   .catch(err => {
    //     this.$message.error('菜单加载失败，请稍后再试！')
    //   })
    // 监听
    this.$bus.$on('toggle', value => {
      this.collapsed = !value
    })
  }
}
</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}
.el-menu-item-group__title {
  padding: 0px;
}
.el-menu-bg {
  background-color: #1f2d3d !important;
}
.el-menu {
  border: none;
}
.logobox {
  height: 60px;
  line-height: 40px;
  color: #9d9d9d;
  font-size: 20px;
  text-align: center;
  padding:0;
}
.logoimg {
  height: 40px;
}
</style>
