// 导入组件
import Vue from 'vue';
import Router from 'vue-router';
// 登录
import login from '@/views/login';
// 首页
import index from '@/views/index';

import BenefitList from "@/views/benefit/BenefitList";
import LifeList from "@/views/life/LifeList";
import TalentReport from "@/views/questionnaire/TalentReport";
import CostCalculator from "@/views/questionnaire/CostCalculator";
import EventList from "@/views/event/EventList";
import CompanyList from "@/views/recruit/CompanyList";
import ServiceList from "@/views/service/ServiceList";
import BayList from "@/views/bay/BayList";
import TagList from "@/views/system/tag/TagList";
import PositionList from "@/views/recruit/PositionList";
import UserList from "@/views/system/UserList";
import ProfileInfo from "@/views/system/ProfileInfo";
import PromotionList from "@/views/promotion/PromotionList.vue";
import PartnerList from "@/views/partner/PartnerList.vue";
import RepresentList from "@/views/represent/RepresentList.vue";
import ServicesCatList from "@/views/service/ServicesCatList.vue";
import RecruitList from "@/views/resume/ResumeList.vue";

// 启用路由
Vue.use(Router);

// 导出路由
export default new Router({
    routes: [
        {
            path: '/',
            name: '',
            component: login,
            hidden: true,
            meta: {
                requireAuth: false
            }
        },
        {
            path: '/login',
            name: '登录',
            component: login,
            hidden: true,
            meta: {
                requireAuth: false
            }
        },
        {
            path: '/index',
            name: '首页',
            component: index,
            iconCls: 'el-icon-tickets',
            children: [
                {
                    path: '/benefit/benefits',
                    name: '福利政策',
                    component: BenefitList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/event/events',
                    name: '活动管理',
                    component: EventList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/service/services',
                    name: '服务管理',
                    component: ServiceList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/service/services_cat',
                    name: '服务分类',
                    component: ServicesCatList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/life/lives',
                    name: '生活管理',
                    component: LifeList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/special-area/bays',
                    name: '港澳专区',
                    component: BayList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/promotions/list',
                    name: '招商板块',
                    component: PromotionList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/partner/list',
                    name: '国际人才合伙人',
                    component: PartnerList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/represent/list',
                    name: '部分入驻代表',
                    component: RepresentList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/recruit/companies',
                    name: '企业管理',
                    component: CompanyList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/recruit/positions',
                    name: '职位管理',
                    component: PositionList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/recruit/resume',
                    name: '简历管理',
                    component: RecruitList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/questionnaire/talentReport',
                    name: '人才报告',
                    component: TalentReport,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/questionnaire/costCalculator',
                    name: '成本计算器',
                    component: CostCalculator,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/system/user',
                    name: '用户管理',
                    component: UserList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/system/tags',
                    name: '标签管理',
                    component: TagList,
                    meta: {
                        requireAuth: true
                    }
                },
                {
                    path: '/profile',
                    name: '个人信息',
                    component: ProfileInfo,
                    meta: {
                        requireAuth: true
                    }
                },
            ]
        }]
})
