<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>企业信息</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form ref="searchForm" :inline="true" :model="formInline" class="search">
      <el-form-item>
        <el-input
            suffix-icon="el-icon-search"
            size="small" v-model="formInline.keyword"
            @keyup.enter.native="search"
            placeholder="请输入关键字"></el-input>
      </el-form-item>
      <el-form-item>
<!--        <el-button size="small" type="primary" icon="el-icon-search" @click="search('searchForm')">搜索</el-button>-->
<!--        <el-button size="small" type="primary" icon="el-icon-plus" @click="handleImport()">导入</el-button>-->
        <el-button size="small" type="primary" icon="el-icon-plus" class="cus-add-button" @click="handleEdit()">添加新的港澳专区信息</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table
        size="small" highlight-current-row  element-loading-text="加载中……" style="width: 100%;"
        v-loading="loading"
        :data="listData"
    >
      <el-table-column align="center" type="selection" width="60"></el-table-column>
      <el-table-column sortable prop="name" label="公司名称 SC/TC/EN" width="600">
        <template slot-scope="scope">
          <div>
<!--            <div>{{ scope.row.uscc }}</div>-->
            <div style="font-weight: 600">{{ scope.row.simplified_name }}</div>
            <div style="margin-top:5px;font-weight: 600">{{ scope.row.traditional_name }}</div>
            <div style="margin-top:5px;font-weight: 600">{{ scope.row.name }}</div>
          </div>
          <div style="margin-top: 10px">
            <div>
<!--              最近更新时间: {{ scope.row.create_date }}-->
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column sortable prop="tag" label="行业标签" width="200"></el-table-column>
      <el-table-column sortable prop="contact" label="联系人" width="100"></el-table-column>
      <el-table-column sortable prop="contact_way" label="联系方式" width="400">
        <template slot-scope="scope">
          <div>
            <div style="white-space: pre-line">{{ scope.row.contact_way }}</div>
          </div>
        </template>
      </el-table-column>
<!--      <el-table-column sortable prop="tradingCert" label="营业执照" width="150"></el-table-column>-->
      <el-table-column align="center" fixed="right" label="操作" min-width="120">
        <template slot-scope="scope">
          <div><el-button size="mini" class="cus-edit-button" @click="handleEdit(scope.$index, scope.row)">编辑</el-button></div>
          <div style="margin-top: 5px"><el-button size="mini" class="cus-deleted-button" type="danger" @click="deleteCompany(scope.$index, scope.row)">删除</el-button></div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 导入界面 -->
    <el-dialog title="上传职位信息" :visible.sync="importDialogVisible" size="30%">
      <el-upload
          ref="upload"
          class="upload-company"
          action="/api/companies/upload-company"
          drag
          :on-remove="handleChange"
          :on-success="handleImportSuccess"
          :on-error="handleImportError"
          :file-list="fileList"
          :headers="headers"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">上传Excle文件，且不超过5M。<a href="../../assets/template/company_template.xlsx">点击下载</a> </div>
      </el-upload>
    </el-dialog>
    <!-- 编辑界面 -->
    <el-drawer :title="title" :visible.sync="drawer" size="70%">
      <CompanyListItem :reload="search" :close="changeDrawer" :language="formInline.language" :uscc="selectedCompanyId"></CompanyListItem>
    </el-drawer>
  </div>
</template>

<script>
import {listCompanies,listCompanies2, removeCompany, removeCompany2} from "@/api";
import Pagination from '@/components/Pagination'
import CompanyListItem from "@/views/recruit/CompanyListItem";
export default {
  name: 'CompanyList',
  data() {
    return {
      loading: false, //是显示加载
      drawer: false,
      importDialogVisible: false,
      selectedCompanyId: null,
      title: '',
      formInline: {
        page: 0,
        size: 10,
        keyword: '',
        category: '',
        language: null
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      userparm: [], //搜索权限
      listData: [], //用户数据
      fileList: [],
      // 分页参数
      pageparm: {
        currentPage: 0,
        pageSize: 10,
        total: 10
      }
    }
  },
  // 注册组件
  components: {
    CompanyListItem,
    Pagination
  },
  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.getdata(this.formInline)
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    getdata(parameter) {
      this.loading = true

      listCompanies2(parameter).then(res => {
        this.loading = false;
        if (res.numberOfElements > 0) {
          console.log('Get: ', res.totalElements, res.totalPages, res.number, res.size);
          this.listData = res.data;
          this.pageparm.currentPage = res.number + 1;
          this.pageparm.pageSize = res.size;
          this.pageparm.total = res.totalElements;
        } else {
          console.log('@ no life data.')
        }
      }).catch(error => {
        this.loading = false;
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      })

    },
    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage - 1
      this.formInline.size = parm.pageSize
      this.getdata(this.formInline)
    },
    // 搜索事件
    search() {
      this.getdata(this.formInline)
    },
    handleImport: function () {
      this.importDialogVisible = true;
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    handleImportSuccess: function (res) {
      if (res) {
        this.$message.success('企业信息成功导入' + res.length + '条');
        this.importDialogVisible = false;
        this.fileList.slice(0,3);
      }
    },
    handleImportError: function (err) {
      if (err) {
        const error = JSON.parse(err.message);
        console.log('Error: ', error);

        this.$message.error('企业信息导入失败: ' + error.message);
      }
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    //显示编辑界面
    handleEdit: function(index, row) {
      // console.log('@', index, row);
      if (row !== undefined && row !== 'undefined') {
        this.title = '修改职位信息'
        this.selectedCompanyId = row.uscc;
      } else {
        this.title = '添加职位信息'
        this.selectedCompanyId = null;
      }
      this.drawer = true;
    },
    changeDrawer() {
      // console.log('@ call changeDrawer()');
      this.drawer = !this.drawer;
    },

    // 删除一项职位信息
    deleteCompany(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {uscc: row.uscc};
        removeCompany2(params).then(res => {
          console.log('@delete', res);
          setTimeout(() => {
            this.$message.success('该职位信息已删除!');
            this.getdata(this.formInline);
          }, 1000);
        }).catch(err => {
          this.loading = false
          console.log('Error: ', err);
          this.$message.error('该信息删除失败，请稍后再试！')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
  }
}
</script>

<style scoped>
@import "@/assets/css/global.css";

.search {
  margin-top: 20px;
}

.upload-company {
  height: 400px;
}

.el-tag--dark.el-tag--success {
  border-color: #4B08B0;
}

</style>
