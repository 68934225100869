<template>
  <div>
    <el-descriptions title="用户信息">
      <el-descriptions-item label="账号"> {{user.username}} </el-descriptions-item>
      <el-descriptions-item label="姓名"> {{user.name}} </el-descriptions-item>
      <el-descriptions-item label="电子邮箱"> {{user.email}} </el-descriptions-item>
      <el-descriptions-item label="学历"> {{user.education}} </el-descriptions-item>
      <el-descriptions-item label="国籍"> {{user.country}} </el-descriptions-item>
      <el-descriptions-item label="年龄"> {{user.age}} </el-descriptions-item>
    </el-descriptions>
    <el-row>
      <el-col :span="4" :offset="19"><el-switch v-model="modification" active-text="修改密码"></el-switch></el-col>
    </el-row>

    <el-form v-show="modification" label-position="left" :model="passwdInfo" :rules="rules" ref="passwdForm" label-width="100px" class="demo-ruleForm">
      <el-row>
        <el-col :span="16" :offset="4">
          <h3 class="title">密码修改</h3>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16" :offset="4">
          <el-form-item label="用户名" prop="username">
            <el-input type="text" :value="passwdInfo.username" auto-complete="off" readonly placeholder="请输入用户名"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16" :offset="4">
          <el-form-item label="原密码" prop="oldPasswd">
            <el-input type="password" v-model="passwdInfo.oldPasswd" auto-complete="off" placeholder="请输入原登录密码" show-password></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16" :offset="4">
          <el-form-item label="新密码" prop="password">
            <el-input type="password" v-model="passwdInfo.password" auto-complete="off" placeholder="请输入新的登录密码" show-password></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16" :offset="4">
          <el-form-item label="重复输入" prop="password2">
            <el-input type="password2" v-model="passwdInfo.password2" auto-complete="off" placeholder="请再次输入新的密码" show-password></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="16" :offset="4">
          <el-form-item>
            <el-button type="primary" @click="submitForm('passwdForm')">提交</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {changePasswd} from "@/api";

export default {
  name: "ProfileInfo",
  data() {
    var validPasswd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入登录密码'));
      } else if (value !== this.passwdInfo.password) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    };
    return {
      user: '',
      passwdInfo: {
        username: '',
        oldPasswd: '',
        password: '',
        password2: ''
      },
      rules: {
        username: [{required: true, message: '用户名不能为空', trigger: 'blur'}],
        oldPasswd: [{required: true, message: '原登录密码不能为空', trigger: 'blur'}],
        password: [
          {required: true, message: '请输入新的登录密码', trigger: 'blur'},
          {min: 8, message: '输入密码长度必须大于6位'},
          {pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,30}$/, message: '必须包含字母大写、小写、数字、特殊字符中的三种'},
          {
            validator: (rule, value, callback) => {
              if (value === this.passwdInfo.username) callback(new Error('密码不能与用户名相同'))
              else callback()
            },
          },
        ],
        password2: [{required: true, validator: validPasswd, trigger: 'blur'}],
      },
      modification: false,
    }
  },
  mounted() {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      this.user = JSON.parse(userInfo);
      this.passwdInfo.username = this.user.username;
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          changePasswd(this.passwdInfo).then(res => {
            if (res) {
              this.$message.success('密码修改成功');
            } else {
              this.$message.warning('返回值异常');
            }
            this.logout();
          }).catch(error => {
            this.$message.error("密码修改失败：" + error.response.data.message);
            console.log('#', error);
          }).finally(() => {
            this.modification = false;
          });
        } else {
          console.log(this.passwdInfo);
          return false;
        }
      });
    },
    logout() {
      setTimeout(() => {
        this.$store.commit('logout', 'false')
        this.$router.push({ path: '/login' })
        this.$message.success('密码已修改退出重新登录');
      }, 1000);
    }
  }
}
</script>

<style scoped>

</style>