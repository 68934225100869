<template xmlns:el-col="http://www.w3.org/1999/html">
  <div class="b-form"  style="background: #EFEFEF;padding: 20px">
    <el-form ref="pForm" :model="promotions" :rules="rules" size="small" label-width="80px" label-position="top">


      <el-row gutter="20">
        <el-col :span="16">
          <div class="cus-card">
            <el-form-item label="企业名称简体中文" prop="title">
              <el-input v-model="promotions.cn_label" aria-placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="企业名称繁体中文" prop="title">
              <el-input v-model="promotions.tc_label" aria-placeholder=""></el-input>
            </el-form-item>

            <el-form-item label="企业名称英文Engilsh" prop="title">
              <el-input v-model="promotions.en_label" aria-placeholder=""></el-input>
            </el-form-item>
          </div>

        </el-col>

        <el-col :span="8">
          <div style="margin-top: -40px;position: fixed;top: 60px;right: 80px;z-index: 99999">
            <el-form-item >
              <el-button type="primary"  class="cus-save-button" @click="onSubmit('pForm')">保存</el-button>
<!--              <el-button type="info" @click="onClose()">取消</el-button>-->
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="发布状态" prop="status">
              <el-switch v-model="isRelease" @change="setRelease" active-text="前端可见" active-color="#000000"	 inactive-text="已隐藏"/>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="企业网址" prop="title">
              <el-input v-model="promotions.url" aria-placeholder=""></el-input>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="请上传企业Logo" prop="banner">
              <div style="color: rgba(54, 65, 85, 0.50);font-size: 14px;margin-top: -10px;margin-bottom: 5px">图片建议尺寸为1620px * 640px</div>
              <el-upload
                  list-type="picture-card"
                  :action="uploader.url"
                  :accept="'image/*'"
                  :limit="1"
                  :on-preview="handleBannerPreview"
                  :on-remove="handleBannerRemove"
                  :on-success="uploadBannerSuccess"
                  :on-error="uploadBannerError"
                  :file-list="uploader.bannerList"
                  :headers="uploader.headers">
                <i class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <el-dialog :visible.sync="uploader.bannerVisible">
                <img :src="promotions.banner" alt="">
              </el-dialog>
            </el-form-item>
          </div>
        </el-col>
      </el-row>

    </el-form>
  </div>
</template>

<script>
import {getPartner, addPartner, editPartner} from "@/api";

export default {
  name: 'PartnerListItem',
  props: {
    pId: {
      type: Number
    },
    language: {
      type: String
    },
    close: {
      type: Function
    },
    reload: {
      type: Function
    }
  },
  data() {
    return {
      promotions: {
        id: '',
        language: 'EN',
        cn_label: '',
        tc_label: '',
        en_label: '',
        url: '',
        banner: '',
        priority: 1,
        status: 0,
      },
      rules: {
        // language: [{required: true, message: '请选择语言', trigger: 'blur'}],
        // catalog: [{required: true, message: '请选择文档分类', trigger: 'blur'}],
        // title: [
        //   {required: true, message: '请输入文档标题', trigger: 'blur'},
        //   {min: 1, max: 256, message: '标题长度在5 到 256个字符', trigger: 'blur'},
        // ],
        // summary: [{max: 1000, message: '标题长度小于1000个字符', trigger: 'blur'},
        // ],
      },
      uploader: {
        url: "/m_api/admin/resources/upload", // 图片上传请求地址,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        posterList: [],
        bannerList: [],
        posterVisible: false,
        bannerVisible: false,
      },
      lockedLanguage: false,
      isTop: false,
      isRelease: false,
      editable: true,
      contentHeight: 520,
    }
  },
  methods: {
    setTop() {
      this.promotions.priority = this.isTop ? 9 : 1;
    },
    setRelease() {
      this.promotions.status = this.isRelease ? 1 : 0;
    },
    onSubmit(formName) {
      if (this.pId) {
        const params = {id: this.pId};
        editPartner(params, this.promotions).then(res => {
          if (res) {
            console.log('保存成功, id: ', res.id);
            this.$message.success('数据保存成功');
          } else {
            this.$message.warning('返回值异常');
          }
        }).catch(error => {
          this.$message.error("数据保存失败，请稍后重试")
          console.log('#', error);
        });
      } else {
        addPartner(this.promotions).then(res => {
          if (res) {
            console.log('保存成功, id: ', res.id);
            this.$message.success('数据保存成功');
          } else {
            this.$message.warning('返回值异常');
          }
        }).catch(error => {
          this.$message.error("数据保存失败，请稍后重试")
          console.log('#', error);
        });
      }
      setTimeout(() => {
        this.reload();
        this.close();
        this.editable = true;
      }, 1000);
    },
    onClose() {
      // console.log('$ call onCancel');
      this.editable = true;
      this.close();
    },
    getItemData(pId, language) {
      const params = {
        pId,
        language
      }
      getPartner(params).then(res => {
        console.log("res ====== ")
        console.log(res)
        console.log(res.pId)
        if (res.pId > 0) {
          this.promotions = res;
          // this.isTop = this.promotions.priority === 9;
          this.isRelease = this.promotions.status > 0;

          this.uploader.bannerList = [];
          if (this.promotions.banner) {
            this.uploader.bannerList.push({name: 'banner', url: this.promotions.banner});
          }
        } else {
          this.$message.error('该文档数据不存在，编号：' + pId);
        }
      }).catch(error => {
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      });
    },
    changeContentCN(value) {
      console.log('@ call changeContent');
      this.htmlContent.CN = value;
    },
    changeContentTC(value) {
      console.log('@ call changeContent');
      this.htmlContent.TC = value;
    },
    changeContentEN(value) {
      console.log('@ call changeContent');
      this.htmlContent.EN = value;
    },
    initItem() {
      this.promotions = {
        id: '',
        cn_label: '',
        tc_label: '',
        en_label: '',
        en_desc: '',
        cn_desc: '',
        tc_desc: '',
        banner: '',
        priority: 1,
        status: 0,
      };
      this.uploader.posterList = [];
      this.uploader.bannerList = [];
      this.htmlContent = {CN: '', TC: '', EN: ''};
    },
    handlePosterPreview(file) {
      console.log('@poster@', file);
      this.uploader.posterVisible = true;
    },
    handlePosterRemove(file, fileList) {
      this.promotions.poster = '';
      console.log('@poster@', file, fileList);
    },
    uploadPosterSuccess(res, file) {
      console.log('@poster@: ', res);
      this.promotions.poster = res;
      console.log('@poster@: ', file);
    },
    uploadPosterError() {
      this.$message.error("封面图片上传失败");
    },
    handleBannerPreview(file) {
      console.log('@banner@', file);
      this.uploader.bannerVisible = true;
    },
    handleBannerRemove(file, fileList) {
      console.log('@banner@', file, fileList);
      this.promotions.banner = '';
    },
    uploadBannerSuccess(res, file) {
      console.log('@banner@: ', res);
      this.promotions.banner = res.path;
      console.log('@banner@: ', file);
    },
    uploadBannerError() {
      this.$message.error("横幅图片上传失败");
    },
  },
  watch: {
    pId(value) {
      console.log("watch")
      if (value) {
        console.log('Edit promotions,', value);
        this.getItemData(value);
      } else {
        this.lockedLanguage = false;
        this.initItem();
      }
    }
  },
  mounted() {
    if (this.pId) {
      if (this.promotions.id === '') {
        this.getItemData(this.pId, this.language);
      }
    }

  },
  beforeDestroy() {
    this.$off('input');
    this.initBay();
  }
}
</script>

<style scoped>
.cus-card {
  border-radius: 15px;
  background: #FFF;
  padding: 20px;
  margin-bottom: 20px;
}

.avatar-uploader .el-update {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-update:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.tips {
  line-height: 16px;
}

.b-form .el-form-item__label {
  color: #364155 !important;;
  font-size: 14px !important;;
  font-style: normal !important;;
  font-weight: 600 !important;
  line-height: normal;
  padding: 0px !important;
  margin-top: 0px;
  background: red;
}
</style>
