<template xmlns:el-col="http://www.w3.org/1999/html">
  <div class="b-form"  style="background: #EFEFEF;padding: 20px">
    <el-form ref="eventForm" :model="event" :rules="rules" size="small" label-width="80px" label-position="top">
      <el-row gutter="20">
        <el-col :span="16">
          <div class="cus-card">
            <el-form-item label="简体中文活动名称" prop="title">
              <el-input v-model="event.title.CN" aria-placeholder="e.g. 第九届深港（前海）人才合作年会"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文活动名称" prop="title">
              <el-input v-model="event.title.TC" aria-placeholder="e.g.  第九屆深港（前海）人才合作年會"></el-input>
            </el-form-item>

            <el-form-item label="English活动名称" prop="title">
              <el-input v-model="event.title.EN" aria-placeholder="e.g.  The Ninth Shenzhen-Hong Kong (Qianhai) Talent Cooperation Annual Conference"></el-input>
            </el-form-item>
            <el-form-item label="简体中文举办地点" prop="department">
              <el-input v-model="event.geo.CN" aria-placeholder="请输入活动举办地点" />
            </el-form-item>
            <el-form-item label="繁体中文举办地点" prop="department">
              <el-input v-model="event.geo.TC" aria-placeholder="请输入活动举办地点" />
            </el-form-item>
            <el-form-item label="English举办地点" prop="department">
              <el-input v-model="event.geo.EN" aria-placeholder="请输入活动举办地点" />
            </el-form-item>
            <el-form-item label="举办日期">
              <el-date-picker v-model="holdDate" type="datetimerange" start-placeholder="起始时间" end-placeholder="结束时间">
              </el-date-picker>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文摘要" prop="summary">
              <el-input v-model="event.summary.CN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文摘要" prop="summary">
              <el-input v-model="event.summary.TC" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>

            <el-form-item label="English摘要" prop="summary">
              <el-input v-model="event.summary.EN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文正文内容" prop="content">
              <CustomEditor ref="quillEditor_CN" :value="htmlContent.CN" toref="quill_CN" :editable="editable"/>
            </el-form-item>

            <el-form-item label="繁体中文正文内容" prop="content">
              <CustomEditor ref="quillEditor_TC" :value="htmlContent.TC" toref="quill_TC" :editable="editable"/>
            </el-form-item>

            <el-form-item label="English正文内容" prop="content">
              <CustomEditor ref="quillEditor_EN" :value="htmlContent.EN" toref="quill_EN" :editable="editable"/>
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="简体中文搜索引擎元描述" prop="meta_desc">
              <el-input v-model="event.meta_desc.CN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
            <el-form-item label="繁体中文搜索引擎元描述" prop="meta_desc">
              <el-input v-model="event.meta_desc.TC" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>

            <el-form-item label="English搜索引擎元描述" prop="meta_desc">
              <el-input v-model="event.meta_desc.EN" type="textarea" :rows="4" aria-placeholder="请输入摘要"></el-input>
            </el-form-item>
          </div>

        </el-col>

        <el-col :span="8">
          <div style="margin-top: -40px;position: fixed;top: 60px;right: 80px;z-index: 99999">
            <el-form-item >
<!--              <el-button @click="onRefresh">刷新</el-button>-->
              <el-button type="primary" class="cus-save-button" @click="onSubmit('eventForm')">保存</el-button>
<!--              <el-button type="info" @click="onClose()">取消</el-button>-->
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="发布状态" prop="status">
              <el-select v-model="event.status"  placeholder="请选择活动状态" >
                <el-option value=1 label="即将到来"></el-option>
                <el-option value=2 label="进行中"></el-option>
                <el-option value=3 label="往期回顾"></el-option>
                <el-option value=0 label="未发布"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="排序" prop="status">
              <el-switch v-model="isTop" @change="setTop" active-color="#000000" active-text="置顶" inactive-text="常规"/>
            </el-form-item>
            <el-form-item label="港澳" prop="status">
              <el-switch v-model="event.bay" active-color="#000000" active-text="面向港澳" />
            </el-form-item>
          </div>

          <div class="cus-card">
            <el-form-item label="活动主题" prop="catalog">
              <el-select v-model="event.catalog" allow-create filterable placeholder="请选择活动主题">
                <el-option v-for="item in eventCatalogs" :key="item.code" :value="item.code" :label="item.cnlabel"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="人才类型" prop="categories">
              <el-select v-model="event.categories" multiple placeholder="请选择人才类型">
                <el-option value="FT" label="外籍人才"></el-option>
                <el-option value="HKMT" label="港澳人才"></el-option>
                <el-option value="MT" label="大陆人才"></el-option>
                <el-option value="RT" label="海外回国人员"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="人才场景" prop="scenes">
              <el-select v-model="event.scenes" multiple placeholder="请选择人才场景">
                <el-option value="Work" label="工作"></el-option>
                <el-option value="Study" label="学习"></el-option>
                <el-option value="Invest" label="创业"></el-option>
                <el-option value="Life" label="置业"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="封面图片" prop="poster">
              <div style="color: rgba(54, 65, 85, 0.50);font-size: 14px;margin-top: -10px;margin-bottom: 5px">图片建议尺寸为780px * 500px</div>
              <el-upload
                  list-type="picture-card"
                  :action="uploader.url"
                  :accept="'image/*'"
                  :limit="1"
                  :on-preview="handlePosterPreview"
                  :on-remove="handlePosterRemove"
                  :on-success="uploadPosterSuccess"
                  :on-error="uploadPosterError"
                  :file-list="uploader.posterList"
                  :headers="uploader.headers">
                <i class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <el-dialog :visible.sync="uploader.posterVisible">
                <img :src="event.poster" alt="">
              </el-dialog>
            </el-form-item>
          </div>
          <div class="cus-card">
            <el-form-item label="横幅图片" prop="banner">
              <div style="color: rgba(54, 65, 85, 0.50);font-size: 14px;margin-top: -10px;margin-bottom: 5px">图片建议尺寸为1255px * 400px</div>
              <el-upload
                  list-type="picture-card"
                  :action="uploader.url"
                  :accept="'image/*'"
                  :limit="1"
                  :on-preview="handleBannerPreview"
                  :on-remove="handleBannerRemove"
                  :on-success="uploadBannerSuccess"
                  :on-error="uploadBannerError"
                  :file-list="uploader.bannerList"
                  :headers="uploader.headers">
                <i class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
              <el-dialog :visible.sync="uploader.bannerVisible">
                <img :src="event.banner" alt="">
              </el-dialog>
            </el-form-item>
          </div>
        </el-col>

      </el-row>


<!--      <div>-->
<!--        <el-row>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="语言" prop="language">-->
<!--              <el-select v-model="event.language" :disabled="lockedLanguage" placeholder="请选择文档语言">-->
<!--                <el-option value="EN" label="英语"></el-option>-->
<!--                <el-option value="CN" label="简体中文"></el-option>-->
<!--                <el-option value="TC" label="繁体中文"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="人才类型" prop="categories">-->
<!--              <el-select v-model="event.categories" multiple placeholder="请选择人才类型">-->
<!--                <el-option value="FT" label="外籍人才"></el-option>-->
<!--                <el-option value="HKMT" label="港澳人才"></el-option>-->
<!--                <el-option value="MT" label="大陆人才"></el-option>-->
<!--                <el-option value="RT" label="海外回国人员"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="人才场景" prop="scenes">-->
<!--              <el-select v-model="event.scenes" multiple placeholder="请选择人才场景">-->
<!--                <el-option value="Work" label="工作"></el-option>-->
<!--                <el-option value="Study" label="学习"></el-option>-->
<!--                <el-option value="Invest" label="创业"></el-option>-->
<!--                <el-option value="Life" label="置业"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="活动主题" prop="catalog">-->
<!--              <el-select v-model="event.catalog" allow-create filterable placeholder="请选择活动主题">-->
<!--                <el-option v-for="item in eventCatalogs" :key="item.code" :value="item.code" :label="item.cnlabel"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="活动状态" prop="status">-->
<!--              <el-select v-model="event.status"  placeholder="请选择活动状态" >-->
<!--                <el-option value=1 label="即将到来"></el-option>-->
<!--                <el-option value=2 label="进行中"></el-option>-->
<!--                <el-option value=3 label="往期回顾"></el-option>-->
<!--                <el-option value=0 label="未发布"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item>-->
<!--              <el-switch v-model="isTop" @change="setTop" active-text="置顶" />-->
<!--              <el-divider direction="vertical"/>-->
<!--              <el-switch v-model="event.bay" active-text="面向港澳" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="10">-->
<!--            <el-form-item label="举办日期">-->
<!--              <el-date-picker v-model="holdDate" type="datetimerange" start-placeholder="起始时间" end-placeholder="结束时间">-->
<!--              </el-date-picker>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="14">-->
<!--            <el-form-item label="举办地点" prop="department">-->
<!--              <el-input v-model="event.location" aria-placeholder="请输入活动举办地点" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="封面图片" prop="poster">-->
<!--              <el-upload-->
<!--                  list-type="picture-card"-->
<!--                  :action="uploader.url"-->
<!--                  :accept="'image/*'"-->
<!--                  :limit="1"-->
<!--                  :on-preview="handlePosterPreview"-->
<!--                  :on-remove="handlePosterRemove"-->
<!--                  :on-success="uploadPosterSuccess"-->
<!--                  :on-error="uploadPosterError"-->
<!--                  :file-list="uploader.posterList"-->
<!--                  :headers="uploader.headers">-->
<!--                <i class="el-icon-plus avatar-uploader-icon" />-->
<!--              </el-upload>-->
<!--              <el-dialog :visible.sync="uploader.posterVisible">-->
<!--                <img :src="event.poster" alt="">-->
<!--              </el-dialog>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="横幅图片" prop="banner">-->
<!--              <el-upload-->
<!--                  list-type="picture-card"-->
<!--                  :action="uploader.url"-->
<!--                  :accept="'image/*'"-->
<!--                  :limit="1"-->
<!--                  :on-preview="handleBannerPreview"-->
<!--                  :on-remove="handleBannerRemove"-->
<!--                  :on-success="uploadBannerSuccess"-->
<!--                  :on-error="uploadBannerError"-->
<!--                  :headers="uploader.headers">-->
<!--                <i class="el-icon-plus" />-->
<!--              </el-upload>-->
<!--              <el-dialog :visible.sync="uploader.bannerVisible">-->
<!--                <img :src="event.banner" alt="">-->
<!--              </el-dialog>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item>-->
<!--              <el-switch v-model="editable" active-text="是否编辑内容" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="24">-->
<!--            <el-form-item label="标题" prop="title">-->
<!--              <el-input v-model="event.title" aria-placeholder="请输入标题"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="18">-->
<!--            <el-form-item label="通用标签" prop="tags">-->
<!--              <template>-->
<!--                <el-tag v-for="(item, idx) in event.tags" :key="idx" closable-->
<!--                        :type="idx % 2 === 0 ? 'warning' : ''" effect="plain"-->
<!--                        @close="removeTag(item)"-->
<!--                > {{ item }}</el-tag>-->
<!--              </template>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="6">-->
<!--            <el-form-item>-->
<!--              <el-button type="success" @click="openDialog">添加通用标签</el-button>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="24">-->
<!--            <el-form-item label="摘要" prop="summary">-->
<!--              <el-input v-model="event.summary" type="textarea" :rows="2" aria-placeholder="请输入摘要"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="24">-->
<!--            <el-form-item label="内容" prop="content">-->
<!--              <CustomEditor ref="quillEditor" :value="event.content" :editable="editable"/>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row>-->
<!--          <el-col :span="24">-->
<!--            <el-form-item >-->
<!--              <el-button type="primary" @click="onSubmit('eventForm')">保存</el-button>-->
<!--              <el-button type="info" @click="onClose()">取消</el-button>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--      </div>-->

    </el-form>
    <!--  维护通用标签  -->
    <el-dialog title="添加通用标签" :visible.sync="tagDialogVisible" :append-to-body="true" width="30%" @click='closeDialog'>
      <el-input v-model="tag" ref="tagInput" placeholder="请输入标签关键字" @keyup.enter.native="addTag"></el-input>
      <em class="tips"><i class="el-icon-info"></i>输入回车添加标签</em>
    </el-dialog>
  </div>
</template>

<script>
import CustomEditor from "@/components/CustomEditor";
import { getEvent, getEvent2, addEvent, editEvent, editEvent2, addContent2} from "@/api";
import { eventCatalogs } from "@/utils/constants";

export default {
  name: 'EventListItem',
  components: {CustomEditor},
  props: {
    articleId: {
      type: String
    },
    language: {
      type: String
    },
    close: {
      type: Function
    },
    reload: {
      type: Function
    }
  },
  data() {
    return {
      event: {
        id: '',
        language: 'EN',
        title: {CN: '', TC: '', EN: ''},
        poster: '',
        banner: '',
        meta_desc: {CN: '', TC: '', EN: ''},
        summary: {CN: '', TC: '', EN: ''},
        content: '',
        catalog: '',
        scenes: [],
        categories: [],
        geo: {CN: '', TC: '', EN: ''},
        tags: [],
        priority: 1,
        status: '',
        beginDate: '',
        endDate: '',
      },
      htmlContent: {CN: '', TC: '', EN: ''},
      rules: {
        // language: [{required: true, message: '请选择语言', trigger: 'blur'}],
        catalog: [{required: true, message: '请选择业务范畴', trigger: 'blur'}],
        scenes: [{required: true, message: '请选择人才场景', trigger: 'blur'}],
        categories: [{required: true, message: '请选择人才类型', trigger: 'blur'}],
        // title: [
        //   {required: true, message: '请输入文档标题', trigger: 'blur'},
        //   {min: 1, max: 256, message: '标题长度在5 到 256个字符', trigger: 'blur'},
        // ],
        // summary: [{max: 256, message: '标题长度小于1000个字符', trigger: 'blur'},
        // ],
        // status: {required: true, message: '请选择状态', trigger: 'blur'},
        // beginDate: [{required: true, message: '请输入活动开始日期', trigger: 'blur'}],
        // endDate: [{required: true, message: '请输入活动结束日期', trigger: 'blur'}],
      },
      uploader: {
        url:"/m_api/admin/resources/upload", // 图片上传请求地址,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        posterList: [],
        bannerList: [],
        posterVisible: false,
        bannerVisible: false,
      },
      holdDate: [],
      lockedLanguage: false,
      isTop: false,
      isRelease: false,
      isReview: false,
      editable: true,
      contentHeight: 520,
      eventCatalogs: eventCatalogs,
      tag: '',
      tagDialogVisible: false,
    }
  },
  methods: {
    setTop() {
      this.event.priority = this.isTop ?  9 : 1;
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('@date range@', this.holdDate);
          this.event.begin_date = this.holdDate[0];
          this.event.end_date = this.holdDate[1];
          if (this.articleId) {
            // console.log('#, ', this.event.content);
            if (this.editable) {
              // console.log('##, ', this.htmlContent);
              this.event.content = this.htmlContent;
              // console.log('###, ', this.event.content);
            }
            const params = { articleId: this.articleId };
            editEvent2(params, this.event).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.id);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          } else {
            console.log('#, ', this.event.content);
            // if (this.editable) {
            //   console.log('##, ', this.htmlContent);
            //   this.event.content = this.htmlContent.replace(/<p><br><\/p>/g,"").replace(/<div><br><\/div>/g,"");
            //   console.log('###, ', this.event.content);
            // }

            this.event.content = this.htmlContent;
            this.event.topic = "Event";
            this.event.topic_title = "E";
            addContent2(this.event).then(res => {
              if (res) {
                console.log('保存成功, id: ', res.articleId);
                this.$message.success('数据保存成功');
              } else {
                this.$message.warning('返回值异常');
              }
            }).catch(error => {
              this.$message.error("数据保存失败，请稍后重试")
              console.log('#', error);
            });
          }
          setTimeout(()=>{
            this.reload();
            this.close();
            // this.editable = false;
            // this.initEvent();
          }, 1000);
        } else {
          console.log(this.event);
          return false;
        }
      });
    },
    onClose() {
      // console.log('$ call onCancel');
      // this.editable = false;
      this.close();
    },
    getEventData(articleId, language) {
      const params = {
        articleId,
        language
      }
      this.initEvent();
      getEvent2(params).then(res => {
        console.log('http response: ', res);
        if (res.articleId) {
          this.event = res;
          this.event.id = res.articleId;
          this.htmlContent = res.content;
          console.log("res.status ===== ");
          this.event.status = res.status ? res.status : 1;
          this.event.bay = (this.event.bay == 1);
          this.isTop = res.priority === 9;
          this.holdDate = [];
          this.holdDate.push(new Date(res.begin_date));
          this.holdDate.push(new Date(res.end_date));
          if (!this.event || this.event.tags === null) this.event.tags = [];

          this.uploader.posterList = [];
          if (this.event.poster) {
            this.uploader.posterList.push({name: 'poster', url: this.event.poster});
          }
          this.uploader.bannerList = [];
          if (this.event.banner) {
            this.uploader.bannerList.push({name: 'banner', url: this.event.banner});
          }
          this.lockedLanguage = true;
        } else {
          this.$message.error('未找到该文档，编号：' + articleId);
        }
      }).catch(error => {
        console.log('#Error: ', error);
        this.$message.error('数据加载失败，请稍后重试!')
      });
    },
    changeContentCN(value) {
      console.log('@ call changeContent' );
      this.htmlContent.CN = value;
    },
    changeContentTC(value) {
      console.log('@ call changeContent' );
      this.htmlContent.TC = value;
    },
    changeContentEN(value) {
      console.log('@ call changeContent' );
      this.htmlContent.EN = value;
    },


    initEvent() {
      const lang = this.event.language;
      this.event = {
        id: '',
        language: '',
        title: {CN: '', TC: '', EN: ''},
        poster: '',
        banner: '',
        meta_desc: {CN: '', TC: '', EN: ''},
        summary: {CN: '', TC: '', EN: ''},
        content: {CN: '', TC: '', EN: ''},
        catalog: '',
        scenes: [],
        categories: [],
        geo: {CN: '', TC: '', EN: ''},
        tags: [],
        priority: 1,
        status: 1,
        bay: false,
        beginDate: '',
        endDate: '',
      };
      this.event.language = lang;
      this.holdDate = [];
      this.uploader.posterList = [];
      this.uploader.bannerList = [];
      this.htmlContent = {CN: '', TC: '', EN: ''};
      this.tag = '';
    },

    handlePosterPreview(file) {
      console.log('@poster@', file);
      this.uploader.posterVisible = true;
    },
    handlePosterRemove(file, fileList) {
      this.event.poster = '';
      console.log('@poster@', file, fileList);
    },
    uploadPosterSuccess(res, file) {
      console.log('@poster@: ', res);
      this.event.poster = res.path;
      console.log('@poster@: ', file);
    },
    uploadPosterError() {
      this.$message.error("封面图片上传失败");
    },
    handleBannerPreview(file) {
      console.log('@banner@', file);
      this.uploader.posterVisible = true;
    },
    handleBannerRemove(file, fileList) {
      console.log('@banner@', file, fileList);
      this.event.banner='';
    },
    uploadBannerSuccess(res, file) {
      console.log('@banner@: ', res);
      this.event.banner = res.path;;
      console.log('@banner@: ', file);
    },
    uploadBannerError() {
      this.$message.error("横幅图片上传失败");
    },
    openDialog() {
      this.tagDialogVisible = true;
      this.$nextTick(() => {this.$refs.tagInput.focus()})
    },
    closeDialog() {
      this.tagDialogVisible = false;
    },
    addTag() {
      // console.log('tags: ', this.event.tags);
      if (!this.event.tags) this.event.tags = [];
      if (this.tag) {
        this.event.tags.push(this.tag);
        this.tag = '';
      }
    },
    removeTag(tag) {
      this.event.tags.splice(this.event.tags.indexOf(tag), 1);
    },
  },
  watch: {
    articleId(value) {
      console.log(value, ' is changed.');
      if (value) {
        console.log('Edit event,', value);
        let lang = this.event.language;
        if (lang === undefined || lang === null || lang === '') lang = this.language;
        if (lang === undefined || lang === null || lang === '') lang = 'EN';
        this.getEventData(value, this.event.language);
      } else {
        this.lockedLanguage = false;
        this.initEvent();
        this.event.language = this.language;
        console.log('New event');
      }
    },
    language(value) {
      if (value) {
        this.getEventData(this.articleId, value);
      }
    }
  },
  mounted() {
    console.log('@', this.articleId, this.event.id);
    // console.log(this.event.id && this.event.id === '');
    this.event.language = this.language;
    if (this.articleId) {
      if (this.event.id === '') {
        this.getEventData(this.articleId, this.language);
      }
    }

    this.$refs.quillEditor_CN.$on("input", this.changeContentCN);
    this.$refs.quillEditor_TC.$on("input", this.changeContentTC);
    this.$refs.quillEditor_EN.$on("input", this.changeContentEN);

  },
  beforeDestroy() {
    this.$off('input');
    this.initEvent();
  }
}
</script>


<style scoped>
.cus-card{
  border-radius: 15px;
  background: #FFF;
  padding: 20px;
  margin-bottom: 20px;
}
.avatar-uploader .el-update {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-update:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.tips {
  line-height: 16px;
}

.b-form .el-form-item__label{
  color: #364155 !important;;
  font-size: 14px !important;;
  font-style: normal !important;;
  font-weight: 600 !important;
  line-height: normal;
  padding: 0px !important;
  margin-top: 0px;
  background: red;
}
</style>

